import React, { useState, useEffect } from "react";
import { Button, useMediaQuery, useTheme } from '@mui/material';

export default function RangeButton({ allObj, singleObj, handleRange }) {

    const [buttonWidths, setButtonWidths] = useState([]);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
    const theme = useTheme();
    // update width
    useEffect(() => {
        const widths = allObj.map(obj => measureTextWidth(obj.text) + 30); // Add padding
        setButtonWidths(widths);
    }, [allObj]);

    // Function to measure text width
    const measureTextWidth = (text) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = '13px Urbanist'; // Set the font style to match your button text
        return context.measureText(text).width;
    };

    const maxWidth = Math.max(...buttonWidths);

    if (!isMobile) {
        return (
            <>
                <Button

                    key={`${singleObj.id}-button`}
                    id={singleObj.id}
                    onClick={() => handleRange(singleObj.id)}
                    variant="contained"
                    sx={{
                        marginBottom: "3px",
                        border:`1px solid ${theme.palette.secondary.light}`,
                        backgroundColor:theme.palette.background.default,
                        color:theme.palette.text.secondary,
                        padding: 0,
                        marginLeft: "3px",
                        fontSize:'0.875rem',
                        fontWeight: 800,
                        width: maxWidth ? `${maxWidth}px` : '150px'
                    }}
                >
                    {singleObj.text}
                </Button >
            </>
        );
    } else {
        return null
    }
}