import React, { useState, useEffect, useCallback } from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./index";
import oxoia_theme from "./theme";
import { CssBaseline } from "@mui/material";
import {
  experimental_extendTheme as extendTheme,
  Experimental_CssVarsProvider as CssVarsProvider,
} from "@mui/material/styles";
import Header from "./Components/PageStructure/Header";
import SideMenu from "./Components/PageStructure/SideMenu";
// import Footer from "./Components/Pages/Footer";
// import Home from "./Components/Pages/Home";
import Login from "./Components/Accounts/Login";
import Register from "./Components/Accounts/Register";
// import ChangePassword from "./Components/Accounts/ChangePassword";
// import Customers from "./Landing/Customers/Customers";
// import Partners from "./Components/Customers/Partners";

import Campuses from "./Components/Landing/Campuses";
import DetailCampus from "./Components/Campus/DetailCampus";

import Tasks from "./Components/Tasks/Tasks";
import EditTask from "./Components/Tasks/EditTask";
import DetailTask from "./Components/Tasks/DetailTask";
import Delete from "./Components/Tasks/Delete";
import Report from "./Components/Generic/Report";
import CreateTask from "./Components/Tasks/CreateTask";
import Settings from "./Components/Accounts/Settings";
import Calculator from "./Components/Calculator/Calculator";
import DirectLinkHandler from "./Components/Generic/DirectLinkHandler";

// import Wishlist from "./Components/Pages/Wishlist";
import Charts from "./Components/Charts/Charts";
import ChartFavorites from "./Components/Charts/ChartFavorites";
// import Kpis from "./Components/Dashboards/Kpis";
// import CampusMeasures from "./Components/Customers/CampusMeasures";
// import CampusKpis from "./Components/Customers/CampusKpis";
// import CampusControl from "./Components/Customers/CampusControl";
// import ChartSelector from "./Components/Dashboards/ChartSelector";
import ManualTheme from "./Components/Accounts/ManualTheme";
import Maintenance from "./Components/Maintenance";
import ContactForm from "./Components/PageStructure/ContactForm";

import { NotificationProvider } from "./Components/Generic/NotificationContext";

const defaultColors = [
  "#012A39",
  "#019C4B",
  "#FF0000",
  "#FF8500",
  "#BCC7D3",
  "#009C4A",
  "#ffffff",
  "#ffffff",
];

function App() {
  // Starting point for color setup
  const [colorList, setColorList] = useState(() => {
    let storedColors = JSON.parse(localStorage.getItem("colors"));
    let storedMode = localStorage.getItem("colorMode");
    if (storedColors === undefined || storedColors === null)
      storedColors = defaultColors;
    if (storedMode === undefined || storedMode === null || storedMode === "")
      storedMode = "light";
    return { colors: storedColors, mode: storedMode };
  });
  const [mainPage, setMainPage] = useState(1); // 1: map, 2: tasks, 3, charts
  const [subMenu, setSubmenu] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const token = localStorage.getItem("token");
    if (token && token !== "") {
      return true;
    } else {
      return false;
    }
  });

  // This provide auto generated CSS variables
  const theme = extendTheme(oxoia_theme(colorList.colors, colorList.mode));

  const handleLogin = (token) => {
    localStorage.setItem("token", token); // Store the token in local storage
    setIsAuthenticated(true); // Update the state
  };

  const handleLogout = () => {
    localStorage.clear();
    setIsAuthenticated(false);
  };

  const setColorData = useCallback(
    (val) => {
      let newDataSet = { ...colorList };

      if ("mode" in val) newDataSet = { ...newDataSet, mode: val.mode };
      if ("colors" in val) newDataSet = { ...newDataSet, colors: val.colors };
      console.log("color- setColorData", newDataSet);
      setColorList(newDataSet);
    },
    [colorList]
  );

  const setColors = useCallback((val) => {
    console.log("color- setColors", val);
    setColorList(val);
  }, []);
  // Declare maintenance mode state
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

  // Check maintenance mode on mount
  useEffect(() => {
    async function checkMaintenanceMode() {
      try {
        const response = await fetch("/check-maintenance/");

        const data = await response.json();

        setIsMaintenanceMode(data.maintenance_mode); // Update state with the response
      } catch (error) {
        // console.error("Failed to check maintenance mode:", error);
      }
    }
    checkMaintenanceMode();
  }, []);

  const sideMenuProps = { mainPage, setMainPage, subMenu };

  // If in maintenance mode, render the Maintenance component
  if (isMaintenanceMode) {
    return (
      <CssVarsProvider theme={theme}>
        <CssBaseline />
        <Maintenance /> {/* Adjusted to correct component */}
      </CssVarsProvider>
    );
  }

  const ProtectedRoute = ({ isAuthenticated, children }) => {
    const location = useLocation(); // Get the current location

    return isAuthenticated ? (
      <>
        <Header setColorList={setColorData} logout={handleLogout} />
        <SideMenu {...sideMenuProps} />
        {children}
      </>
    ) : (
      (() => {
        // Store the current path before redirecting
        localStorage.setItem(
          "redirectPath",
          location.pathname + location.search
        );
        return <Navigate to="/login" replace />;
      })()
    );
  };

  return (
    <NotificationProvider>
      <CssVarsProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="/" element={<Login authenticate={handleLogin} />} />
            <Route
              path="/campuses"
              element={
                <ProtectedRoute
                  isAuthenticated={isAuthenticated}
                  children={
                    <Campuses addSubMenu={setSubmenu} {...sideMenuProps} />
                  }
                />
              }
            />
            {/* Ensure the route is defined */}
            <Route
              path="/login"
              element={<Login authenticate={handleLogin} />}
            />
            <Route path="/register" element={<Register />} />
            <Route
              path="/settings"
              element={
                <ProtectedRoute
                  isAuthenticated={isAuthenticated}
                  children={<Settings />}
                />
              }
            />
            <Route
              path="/theme"
              element={
                <ProtectedRoute
                  isAuthenticated={isAuthenticated}
                  children={
                    <ManualTheme
                      setColorList={setColors}
                      colorList={colorList}
                    />
                  }
                />
              }
            />
            <Route
              path="/campus-detail/:id"
              element={
                <ProtectedRoute
                  isAuthenticated={isAuthenticated}
                  children={<DetailCampus />}
                />
              }
            />
            <Route
              path="/tasks"
              element={
                <ProtectedRoute
                  isAuthenticated={isAuthenticated}
                  children={<Tasks />}
                />
              }
            />
            <Route
              path="/tasks/:id/edit"
              element={
                <ProtectedRoute
                  isAuthenticated={isAuthenticated}
                  children={<EditTask />}
                />
              }
            />
            <Route
              path="/tasks/:id/detail"
              element={
                <ProtectedRoute
                  isAuthenticated={isAuthenticated}
                  children={<DetailTask />}
                />
              }
            />
            <Route
              path="/tasks/delete"
              element={
                <ProtectedRoute
                  isAuthenticated={isAuthenticated}
                  children={<Delete />}
                />
              }
            />
            <Route
              path="/tasks/report"
              element={
                <ProtectedRoute
                  isAuthenticated={isAuthenticated}
                  children={<Report />}
                />
              }
            />
            <Route
              path="/tasks/create"
              element={
                <ProtectedRoute
                  isAuthenticated={isAuthenticated}
                  children={<CreateTask />}
                />
              }
            />
            <Route
              path="/charts"
              element={
                <ProtectedRoute
                  isAuthenticated={isAuthenticated}
                  children={<Charts />}
                />
              }
            />
            <Route
              path="/charts/favorites"
              element={
                <ProtectedRoute
                  isAuthenticated={isAuthenticated}
                  children={<ChartFavorites />}
                />
              }
            />
            <Route
              path="/calculator"
              element={
                <ProtectedRoute
                  isAuthenticated={isAuthenticated}
                  children={<Calculator />}
                />
              }
            />
            <Route
              path="/contact"
              element={
                <ProtectedRoute
                  isAuthenticated={isAuthenticated}
                  children={<ContactForm />}
                />
              }
            />
            <Route
              path="*"
              element={
                <ProtectedRoute
                  isAuthenticated={isAuthenticated}
                  children={<DirectLinkHandler />}
                />
              }
            />
          </Routes>
        </Router>
      </CssVarsProvider>
    </NotificationProvider>
  );
}

export default App;
