import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import { MENU_ELEVATION, BORDER_RADIUS } from "../../constants";
import { styled } from "@mui/material/styles";

const LangButton = styled(Button)(({ theme }) => ({
  fontSize: "1.2rem",
  padding: "0 8px",
  minWidth: "auto",
  background: "none",
  boxShadow: "none",
  borderRadius: BORDER_RADIUS,
  color: theme.palette.secondary.contrastText,
  "&:hover": {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.secondary.light,
    boxShadow: `0 0 10px ${theme.palette.secondary.contrastText}, 0 0 20px ${theme.palette.secondary.contrastText}`,
  },
}));

function LanguageSelector({ integration }) {
  const { i18n, t } = useTranslation();
  const [lang, setLang] = useState(() => {
    let saved = localStorage.getItem("language");
    if (!saved) {
      const userLanguage = navigator.language;
      if (userLanguage.includes("de")) saved = "de";
      else if (userLanguage.includes("en")) saved = "en";
      else if (userLanguage.includes("fr")) saved = "fr";
      else saved = "en";
    }
    return saved;
  });

  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const token = localStorage.getItem("token");

  const toggleClick = (event) => {
    setMenuOpen((prevOpen) => !prevOpen);
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);

    // Update the user's language preference in the backend
    if (token) {
      axios
        .patch(
          `${process.env.REACT_APP_API_BASE_URL}/api/customers/userprofile/detail/`,
          { language: lang },
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .catch((error) => {
          console.error("Error updating language:", error);
        });
    }
  }, [lang, i18n, token]);

  const changeLanguage = (lng) => {
    setLang(lng);
    if (integration === "header") {
      setMenuOpen(false);
      setAnchorEl(null);
    }
  };

  const languages = ["DE", "EN"]; //, "FR"];

  return (
    <>
      {integration === "noAuth" && (
        <div style={{ display: "flex", alignItems: "center" }}>
          {languages.map((ln, ind) => (
            <React.Fragment key={ind}>
              {ind !== 0 && (
                <Typography
                  variant="body2"
                  className="loginLanguage"
                  style={{ margin: "0 4px" }}
                >
                  |
                </Typography>
              )}
              <LangButton
                onClick={() => changeLanguage(ln.toLowerCase())}
                theme={theme}
                style={{
                  color:
                    lang === ln.toLowerCase()
                      ? theme.palette.secondary.contrastText
                      : theme.palette.secondary.contrastText,
                  fontWeight: lang === ln.toLowerCase() ? "900" : "100",
                }}
              >
                {ln}
              </LangButton>
            </React.Fragment>
          ))}
        </div>
      )}
      {integration === "header" && (
        <MenuItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
          className="settings"
          onClick={toggleClick}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <ListItemIcon className="settings">
              <GTranslateIcon />
            </ListItemIcon>
            <Typography variant="subtitle1" className="settings">
              {t("LANGUAGE")}
            </Typography>
          </div>
          <Menu
            id="language-menu"
            anchorEl={anchorEl}
            open={menuOpen}
            elevation={MENU_ELEVATION}
            MenuListProps={{
              "aria-labelledby": "language-button",
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {languages.map((ln) => (
              <MenuItem
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
                className="settings"
                onClick={() => changeLanguage(ln.toLowerCase())}
                theme={theme}
                key={ln}
              >
                <Typography variant="subtitle1" className="settings">
                  {ln}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </MenuItem>
      )}
    </>
  );
}

export default LanguageSelector;
