import React, { useState } from "react";
import {
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Workspace from "../PageStructure/Workspace";
import ChartTargetSelector from "./ChartTargetSelector";
import ChartDateRangeSelector from "./ChartDateRangeSelector";
import ChartHeader from "./ChartHeader";
import ChartButtons from "./ChartButtons";
import ChartGraphWorkspace from "./ChartGraphWorkspace";


const Charts = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'))
  const isLargeTablet = useMediaQuery((theme) => theme.breakpoints.between('md', 'lg'))
  const isVeryLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('xl'))
  const { t } = useTranslation();
  // This object is managed by ChartHeader
  const [sharedHeaderObject, setSharedHeaderObject] = useState({
    chartHeaderObject: {}, // for links
    targetData: [],
    chartViewData: {},
  });
  // This object is managed by ChartTargetSelection
  const [targetSelection, setTargetSelection] = useState({
    selectedTargetCharts: [],
    selectedTargetKpis: [],
    selectedTargetId: null,
  });
  // This object is managed by ChartDateRangeSelector
  const [dateRangeSelection, setDateRangeSelection] = useState({
    startDate: null,
    startTime: null,
    startDateTime: null,
    endDate: null,
    endTime: null,
    endDateTime: null,
  });
  

  return (
    <Workspace isLoading={false} title={t("CHARTS")}>
      <>
        <ChartButtons
          sharedHeaderObject={sharedHeaderObject}
          targetSelection={targetSelection}
          dateRangeSelection={dateRangeSelection}
        />
        <div
          style={{
            paddingTop: "1rem",
            display: "flex",
            flexDirection: isVeryLargeScreen ? "row" : "column",
            alignItems: `${isMobile ? null : isTablet ? null : isLargeTablet ? null : "flex-start"}`,
            justifyContent: "left",
            // flexWrap: "wrap",
          }}
        >
          <ChartDateRangeSelector objectHandler={setDateRangeSelection} />

          <div style={{
            paddingRight: isVeryLargeScreen ? '1rem' : 0,
            paddingTop: isVeryLargeScreen ? 0 : '1rem',
          }}>

          </div>

          <ChartHeader objectHandler={setSharedHeaderObject} />
          <div style={{
            paddingRight: isVeryLargeScreen ? '1rem' : 0,
            paddingTop: isVeryLargeScreen ? 0 : '1rem',
          }}>

          </div>
          <ChartTargetSelector
            objectHandler={setTargetSelection}
            sharedHeaderObject={sharedHeaderObject}
            label={t("TARGET")}
          />
        </div>
        <div
          style={{
            paddingTop: "1rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "left",
            flexWrap: "wrap",
          }}
        >
        </div>
        {/* {console.log(sharedHeaderObject,targetSelection,dateRangeSelection)} */}
        <ChartGraphWorkspace
            sharedHeaderObject={sharedHeaderObject}
            targetSelection={targetSelection}
            dateRangeSelection={dateRangeSelection}
          />
      </>
    </Workspace>
  );
};

export default Charts;
