import React, { useEffect, useState, useCallback } from "react";
import {
  Stack,
  Typography,
  Button,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { HexColorPicker, HexColorInput } from "react-colorful";
import Workspace from "../PageStructure/Workspace";
import SaveIcon from "@mui/icons-material/Save";
import { useElementSize, useDrawerWidth } from "../../size";
import {
  SIDEBAR_WIDTH_SM,
  BORDER_RADIUS,
  PAPER_ELEVATION,
} from "../../constants";
import axios from "axios";

const ColorField = ({ index, text, setColorList, colorList }) => {
  const [color, setColor] = useState(colorList.colors[index]);
  const { t } = useTranslation();

  const changeColor = (newColor, index) => {
    console.log(`Changing color at index ${index} to ${newColor}`);
    setColor(newColor.toUpperCase());
    const newColorList = [...colorList.colors];
    newColorList[index] = newColor.toUpperCase();
    const modColor = { ...colorList, colors: newColorList };
    localStorage.setItem("colors", JSON.stringify(newColorList));
    setColorList(modColor);
  };

  return (
    <Card
      elevation={PAPER_ELEVATION}
      sx={{
        display: "flex",
        width: { xs: "300px", sm: "400px", md: "400px" },
        height: { xs: "150px", sm: "150px", md: "200px" },
        justifyContent: "space-between",
        overflow: "visible",
        borderRadius: `${BORDER_RADIUS}px`,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", width: "50%" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div" variant="h5">
            {text}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            sx={{ marginTop: { xs: "0px", sm: "0px", md: "25px" } }}
          >
            {t("ExistingCOL")}
          </Typography>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                backgroundColor: color,
                width: "24px",
              }}
            ></div>
            <HexColorInput
              style={{ marginLeft: "5px", width: "80px" }}
              color={color}
              onChange={(val) => changeColor(val, index)}
            />
          </div>
        </CardContent>
      </Box>
      <Box>
        <HexColorPicker
          style={{
            height: useElementSize().colorHeight,
            width: useElementSize().colorHeight,
          }}
          color={color}
          onChange={(val) => changeColor(val, index)}
        />
      </Box>
    </Card>
  );
};

const ManualTheme = React.memo(({ setColorList, colorList }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const drawerWidth = useDrawerWidth();
  const [colors, setColors] = useState(colorList);

  useEffect(() => {
    // if(colors) {
    //   if(colors.colors.toString() ===  colorList.colors.toString()) {
    //     console.log("sss same")
    //   } else {
    //     console.log("sss update")
    //     setColorList(colors)
    //   }

    //   console.log("sss", colors,colors.colors.toString(), colorList.colors.toString())
    //   // setColorList(colors)
    // }
    if (colors) {
      // console.log(colors,colors.colors.toString(), colorList.colors.toString())
      console.log("reload");
      setColorList(colors);
    }
    console.log("test");
  }, [colors]);

  console.log("reload", colors);

  // Fetch the user based theme when loading
  useEffect(() => {
    const fetchTheme = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found");
        return;
      }
      console.log(token, colorList);
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };

      try {
        console.log("Fetching theme data...");
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/customers/user/theme/`,
          config
        );
        console.log("Theme data fetched:", response.data);

        if (response.status === 200) {
          const data = response.data;
          setColors({
            colors: [
              data.primary_color || "#012A39",
              data.secondary_color || "#019C4B",
              data.error_color || "#FF0000",
              data.warning_color || "#FF8500",
              data.info_color || "#BCC7D3",
              data.success_color || "#009C4A",
              data.background_color || "#ffffff",
              data.paper_color || "#ffffff",
            ],
            mode: "light", // or 'dark' if you want to handle that
          });
          // setColorList({
          //   colors: [
          //     data.primary_color || "#012A39",
          //     data.secondary_color || "#019C4B",
          //     data.error_color || "#FF0000",
          //     data.warning_color || "#FF8500",
          //     data.info_color || "#BCC7D3",
          //     data.success_color || "#009C4A",
          //     data.background_color || "#ffffff",
          //     data.paper_color || "#ffffff",
          //   ],
          //   mode: "light", // or 'dark' if you want to handle that
          // });
        }
      } catch (error) {
        console.error("Error fetching theme data:", error);
      } finally {
        setLoading(false);
        console.log("Loading set to false");
      }
    };
    console.log(colors, !colors);
    if (!colors) fetchTheme();
  }, []);
  // }, [setColorList]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found");
      return;
    }

    const config = {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    };

    const themeData = {
      primary_color: colorList.colors[0],
      secondary_color: colorList.colors[1],
      error_color: colorList.colors[2],
      warning_color: colorList.colors[3],
      info_color: colorList.colors[4],
      success_color: colorList.colors[5],
      background_color: colorList.colors[6],
      paper_color: colorList.colors[7],
    };

    try {
      console.log("Saving theme data...");
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/customers/user/theme/`,
        themeData,
        config
      );
      if (response.status === 200) {
        console.log("Theme saved successfully!");
      }
    } catch (error) {
      console.error("Error saving theme:", error);
    }
  };

  const renderColors = (colorList, setColorList) => {
    const boxStyle = {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      flex: "1",
      width: "100%",
    };
    return (
      <Box sx={boxStyle}>
        <Stack
          spacing={{ xs: 1, sm: 4, md: 4 }}
          direction={{ sm: "column", md: "row" }}
          sx={{
            alignItems: { xs: "center", sm: "center", md: "flex-start" },
            paddingBottom: { xs: 1, sm: 4, md: 4 },
          }}
        >
          <ColorField
            key="1"
            index={0}
            text={t("PRIMCOL")}
            colorList={colorList}
            setColorList={setColorList}
          />
          <ColorField
            key="2"
            index={1}
            text={t("SECCOL")}
            colorList={colorList}
            setColorList={setColorList}
          />
        </Stack>

        <Stack
          spacing={{ xs: 1, sm: 4, md: 4 }}
          direction={{ sm: "column", md: "row" }}
          sx={{
            alignItems: { xs: "center", sm: "center", md: "flex-start" },
            paddingBottom: { xs: "2rem", sm: 4, md: 4 },
          }}
        >
          <ColorField
            key="7"
            index={6}
            text={t("BACKG")}
            colorList={colorList}
            setColorList={setColorList}
          />
          <ColorField
            key="8"
            index={7}
            text={t("BACKGPAPER")}
            colorList={colorList}
            setColorList={setColorList}
          />
        </Stack>
      </Box>
    );
  };

  const AddEntry = () => {
    return (
      <Button
        variant="contained"
        className="workspace"
        startIcon={<SaveIcon />}
        onClick={(e) => handleSubmit(e)}
      >
        {t("SAVE")}
      </Button>
    );
  };

  return (
    <Workspace
      isLoading={loading}
      title={t("COLORS")}
      addTitleElementRightSide={<AddEntry />}
    >
      {colors && renderColors(colors, setColors)}
      {/* {renderColors(colors, setColors)} */}
      {/* Special block for mobile devices, do not delete */}
      <div
        style={{ height: drawerWidth === 0 ? `${SIDEBAR_WIDTH_SM}px` : "0px" }}
      ></div>
    </Workspace>
  );
});

export default ManualTheme;
