import React, { useEffect, useRef } from "react";
import { alpha } from '@mui/material/styles';
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { useTheme } from "@mui/material/styles";

const DynamicMarker = ({ center, popupContent, isTriggered }) =>{
  const theme = useTheme();
  const markerRef = useRef(null);
  const roundIcon = new L.DivIcon({
    html: `
    <div style="
      position: relative;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 3px solid ${theme.palette.primary.dark}; 
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 5px ${alpha(theme.palette.secondary.dark, 0.5)};
    ">
      <div style="
        background-color: ${theme.palette.secondary.dark};
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 3px solid white;
        box-shadow: 0 0 5px ${alpha(theme.palette.secondary.dark, 0.5)};
      "></div>
    </div>
  `,
    className: '',
    iconSize: [30, 30],
    iconAnchor: [15, 15],
    popupAnchor: [0, -15],
  });

  useEffect(() => {
    const marker = markerRef.current;

    const handleMouseOver = () => {
        if (marker) {
            const iconElement = marker._icon.querySelector('div');
            if (iconElement) {
                iconElement.style.border = `3px solid ${theme.palette.warning.main}`;
                iconElement.style.boxShadow = `0 0 10px ${alpha(theme.palette.secondary.dark, 0.8)}`;
            }
        }
    };

    const handleMouseOut = () => {
        if (marker) {
            const iconElement = marker._icon.querySelector('div');
            if (iconElement) {
                iconElement.style.border = `4px solid ${theme.palette.primary.dark}`;
                iconElement.style.boxShadow = '0 0 5px rgba(0, 0, 0, 0.5)';
            }
        }
    };

    if (isTriggered && marker) {
        marker.openPopup();
        const iconElement = marker._icon.querySelector('div');

        // Add event listeners
        if (iconElement) {
            iconElement.addEventListener('mouseover', handleMouseOver);
            iconElement.addEventListener('mouseout', handleMouseOut);
        }

        return () => {
            if (iconElement) {
                iconElement.removeEventListener('mouseover', handleMouseOver);
                iconElement.removeEventListener('mouseout', handleMouseOut);
            }
        };
    } else if (marker) {
        const openPopup = () => marker.openPopup();

        marker.on('mouseover', openPopup);
        marker.on('click', openPopup);

        const iconElement = marker._icon.querySelector('div');

        if (iconElement) {
            iconElement.addEventListener('mouseover', handleMouseOver);
            iconElement.addEventListener('mouseout', handleMouseOut);
        }

        return () => {
            if (iconElement) {
                iconElement.removeEventListener('mouseover', handleMouseOver);
                iconElement.removeEventListener('mouseout', handleMouseOut);
            }
        };
    }
  }, [theme,isTriggered]);

  return (
    <Marker ref={markerRef} position={center} icon={roundIcon}>
      <Popup>{popupContent}</Popup>
    </Marker>
  );
}

export default DynamicMarker;