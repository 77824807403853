import React, { useCallback, useEffect, useState, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css"; // Import CSS
import { useTranslation } from "react-i18next";
import GenericAutocomplete from "../Generic/GenericAutocomplete";
import { useDrawerWidth } from "../../size";
import { Paper, useMediaQuery } from "@mui/material";
import { PAPER_ELEVATION, BORDER_RADIUS } from "../../constants";
import {
  fetchCustomerAccessibleCampus,
  fetchChartTargets,
  fetchChartViews,
} from "../actions/api";
import { getElementHeightById } from "./chartFuncs";

const defaultChartHeaderObject = {
  // customer
  customerID: null,

  // campus
  campusId: null,
  campusName: null,
  campusHaystackId: null,
  campusTimeZone: null,

  // chartGroup
  selectedChartGroupLabel: null, // required for autocomplete
  selectedChartGroupData: [], // data for further processing
  // sub group
  selectedChartSubGroupLabel: null, // required for autocomplete
  selectedChartSubGroupData: [],

  selectedSubGroupTarget: null,
  //selectedChartDetails: {},

  selectedNavNames: [],

  // targetIds: [], // target ids
  chartViewDef: null, //
  targetData: [],
  // others
  reset: false,
  chartHeaderObject: null,
};

/**
 * Header alement with selectors for campus, main and subtopic
 * @param {Function} objectHandler - Function that collects targets from api call. Returns the object 'targetData' with an array empty or with data
 * @returns {JSX.Element} The rendered Autocomplete component
 *  */
const ChartHeader = ({ objectHandler }) => {
  const { t } = useTranslation();
  const drawerWidth = useDrawerWidth(); // if 0 => mobile
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme) =>
    theme.breakpoints.between("sm", "md")
  );
  const isLargeTablet = useMediaQuery((theme) =>
    theme.breakpoints.between("md", "lg")
  );
  const isLargeScreen = useMediaQuery((theme) =>
    theme.breakpoints.between("lg", "xl")
  );
  const isVeryLargeScreen = useMediaQuery((theme) =>
    theme.breakpoints.up("xl")
  );
  const [height, setHeight] = useState(0);

  const prevChartHeaderObjectRef = useRef();

  const [allCampuses, setAllCampuses] = useState([]);
  const [selectedCampus, setSelectedCampus] = useState(() => {
    // object
    let saved = localStorage.getItem("selectedCampus");
    return saved === null
      ? null //
      : JSON.parse(saved)
          .flat()
          .map((camp) => (camp ? { ...camp, label: camp.name } : {}))
          .slice(0, 1)[0]; // Take the first campus, multiple not possible
  });

  // Hack for demos, replace '-demo' in haystack id with empty string
  const hackDemo = (str) => {
    if (str.endsWith("-demo")) {
      return str.replace(/-demo$/, "");
    }
    return str;
  };

  const [chartHeaderObject, setChartHeaderObject] = useState(() => {
    const saved = localStorage.getItem("chartHeaderObject");
    const parsed = saved ? JSON.parse(saved) : null;
    if (parsed === null && selectedCampus === null) {
      return {
        ...defaultChartHeaderObject,
      };
    } else if (parsed === null && selectedCampus) {
      return {
        ...defaultChartHeaderObject,
        campusId: selectedCampus.id,
        campusName: selectedCampus.name,
        campusHaystackId: hackDemo(selectedCampus.campus_haystack_model_id),
        campusTimeZone: selectedCampus.timezone,
      };
    } else if (parsed && !selectedCampus) {
      return {
        ...parsed,
      };
    } else {
      return {
        ...parsed,
        campusId: selectedCampus.id,
        campusName: selectedCampus.name,
        campusHaystackId: hackDemo(selectedCampus.campus_haystack_model_id),
        campusTimeZone: selectedCampus.timezone,
      };
    }
  });
  // Update storge
  useEffect(() => {
    // reset hash code if any
    const temp = { ...chartHeaderObject };
    delete temp.hashCode;
    localStorage.setItem("chartHeaderObject", JSON.stringify(temp));
  }, [chartHeaderObject]);

  // Update selectedCampus list in localStorage
  useEffect(() => {
    if (selectedCampus) {
      // Retrieve and parse the existing campuses from localStorage
      const saved = localStorage.getItem("selectedCampus");
      const camps = saved ? JSON.parse(saved) : [];

      // Check if the selectedCampus already exists in the array
      const existingObjectIndex = camps.findIndex(
        (obj) => obj.id === selectedCampus.id
      );

      if (existingObjectIndex === -1) {
        // If it doesn't exist, replace the first item or push if the array is empty
        if (camps.length === 0) {
          camps.push(selectedCampus);
        } else {
          camps[0] = selectedCampus;
        }
        // Update localStorage with the new array
        localStorage.setItem("selectedCampus", JSON.stringify(camps));
      }
    }
  }, [selectedCampus]);

  // Fetch campuses, updata allCampuses and header object
  useEffect(() => {
    const fetchData = async () => {
      try {
        const customerData = await fetchCustomerAccessibleCampus();
        if (customerData.campuses.length > 0) {
          const processedCampuses = customerData.campuses?.map((campus) => ({
            ...campus,
            // NEW LINE
            campus_haystack_model_id: hackDemo(campus.campus_haystack_model_id),
            sites: campus.sites.map((site) => ({
              ...site,
              campus_id: campus.id,
            })),
          }));
          setAllCampuses(processedCampuses);
          setChartHeaderObject((prevChartObject) => ({
            ...prevChartObject,
            // this is updated only here
            customerID: customerData.customer_id,
          }));
        } else {
          console.error(t("No campuses available for this user."));
        }
      } catch (error) {
        console.error(t("Error fetching campuses data."));
      }
    };
    fetchData();
  }, [t]);

  // Size observer
  useEffect(() => {
    const observedElementId = "range-select-paper"; // from ChartDateRangeSelector
    const observedElement = document.getElementById(observedElementId);

    const updateHeight = () => {
      const newHeight = getElementHeightById(observedElementId);
      if (newHeight !== null) {
        setHeight(newHeight); // Update the height state
      }
    };

    // Initial height calculation
    updateHeight();

    // Create a ResizeObserver to monitor height changes
    const resizeObserver = new ResizeObserver(updateHeight);
    if (observedElement) {
      resizeObserver.observe(observedElement); // Start observing the element
    }

    // Cleanup function to disconnect the observer
    return () => {
      if (observedElement) {
        resizeObserver.unobserve(observedElement); // Stop observing the element
      }
    };
  }, []);

  const organizedChartData = async (rawDataPromise) => {
    const rawData = await rawDataPromise;

    const uniqueChartGroupNames = [
      ...new Set(rawData.map((item) => item.chartGroup)),
    ];

    const result = uniqueChartGroupNames.map((name) => {
      const elementsOfSameGroup = rawData.filter((f) => f.chartGroup === name);
      // console.log(
      //   "organizedChartData-elementsOfSameGroup",
      //   elementsOfSameGroup
      // );
      return {
        chartGroupName: name,
        subGroups: elementsOfSameGroup,
      };
    });

    return { chartData: result };
  };

  // Get chart views, if campus changes
  useEffect(() => {
    const fetchData = async () => {
      if (chartHeaderObject.customerID && chartHeaderObject.campusId) {
        const chartData = await organizedChartData(
          fetchChartViews(
            chartHeaderObject.customerID,
            chartHeaderObject.campusId
          )
        );
        setChartHeaderObject((prevChartObject) => ({
          ...prevChartObject,
          ...chartData,
        }));
      }
    };
    fetchData();
  }, [chartHeaderObject.customerID, chartHeaderObject.campusId]);

  // Update parent (Chart.js) which distributes the obects to other components
  useEffect(() => {
    objectHandler({
      chartHeaderObject: chartHeaderObject, // for links
      targetData: chartHeaderObject.targetData,
      chartViewData: {
        customerID: chartHeaderObject.customerID,
        campusId: chartHeaderObject.campusId,
        campusName: chartHeaderObject.campusName,
        searchFunc: chartHeaderObject.selectedChartSubGroupData.searchFunc,
        targetSpec: chartHeaderObject.selectedChartSubGroupData.target,
        charts: chartHeaderObject.selectedChartSubGroupData.charts,
        kpis: chartHeaderObject.selectedChartSubGroupData.kpis,
        campusHaystackId: chartHeaderObject.campusHaystackId,
        campusTimeZone: chartHeaderObject.campusTimeZone,
        chartViewDef: chartHeaderObject.selectedChartViewDef,
      },
    });
  }, [chartHeaderObject, objectHandler]);

  // Call API
  const fetchData = useCallback(async () => {
    try {
      // Destructure the required values from chartHeaderObject
      const {
        customerID,
        campusId,
        selectedSubGroupTarget,
        campusHaystackId,
        selectedChartViewDef,
      } = chartHeaderObject;

      // Construct the oxoia_command string
      const oxoia_command = `${selectedSubGroupTarget},${campusHaystackId},${selectedChartViewDef}`;

      // Fetch the targets from the API
      const targets = await fetchChartTargets(
        customerID,
        campusId,
        oxoia_command
      );
      setChartHeaderObject((prevChartObject) => ({
        ...prevChartObject,
        targetData: targets,
      }));
    } catch (error) {
      console.error("Error fetching chart targets:", error);
    }
  }, [chartHeaderObject]);

  useEffect(() => {
    // compare old and new version, not very nice, but prevents re-rendering
    if (
      JSON.stringify(prevChartHeaderObjectRef.current) !==
      JSON.stringify(chartHeaderObject)
    ) {
      prevChartHeaderObjectRef.current = chartHeaderObject;
      const {
        customerID,
        selectedSubGroupTarget,
        campusId,
        campusHaystackId,
        selectedChartViewDef,
      } = chartHeaderObject;
      // load targets if all values are provided
      if (
        customerID &&
        selectedSubGroupTarget &&
        campusId &&
        campusHaystackId &&
        selectedChartViewDef
      ) {
        fetchData();
      } else {
        // console.log("Missing required data for fetching chart targets:", {
        //   customerID,
        //   selectedSubGroupTarget,
        //   campusId,
        //   campusHaystackId,
        //   selectedChartViewDef,
        // });
      }
    }
  }, [
    chartHeaderObject.customerID,
    chartHeaderObject.selectedSubGroupTarget,
    chartHeaderObject.campusId,
    chartHeaderObject.campusHaystackId,
    chartHeaderObject.selectedChartViewDef,
    fetchData,
    chartHeaderObject,
  ]);

  // Campus selection handler
  const handleSelectCampus = (val) => {
    if (val !== null && val !== undefined) {
      setChartHeaderObject({
        ...chartHeaderObject,
        campusId: val.id,
        campusHaystackId: val.campus_haystack_model_id,
        campusTimeZone: val.timezone ? val.timezone : "Europe/Zurich",

        // resets, resets also targets
        selectedChartSubGroupLabel: null,
        selectedChartSubGroupData: [],
        selectedSubGroupTarget: null,
        selectedSubGroupSearchFunc: null,
        selectedSubGroupChartType: null,
        selectedSubGroupCharts: [],
        selectedSubGroupKpis: [],
        reset: true,

        targetData: [],
      });
      objectHandler({
        targetData: [],
        chartViewData: {},
        chartHeaderObject: null,
      });
      setSelectedCampus(val); // object
    }
  };

  // Chart group selection handler
  const handleSelectChartGroup = (group) => {
    if (group !== null && group !== undefined) {
      // console.log(
      //   "handleSelectChartGroup-selectedChartViewDef",
      //   chartHeaderObject.chartData.find(
      //     (f) => f.chartGroupName === group.label
      //   )
      // );
      setChartHeaderObject({
        ...chartHeaderObject,
        selectedChartGroupLabel: group.label,
        selectedChartGroupData:
          chartHeaderObject.chartData.find(
            (f) => f.chartGroupName === group.label
          )?.subGroups || [],

        // reset all subgroup data, resets also targets
        selectedChartSubGroupLabel: null,
        selectedChartSubGroupData: [],
        selectedSubGroupTarget: null,
        selectedSubGroupSearchFunc: null,
        selectedSubGroupChartType: null,
        selectedSubGroupCharts: [],
        selectedSubGroupKpis: [],
        selectedChartViewDef: null,
        reset: true,
        targetData: [],
      });
      objectHandler({
        targetData: [],
        chartViewData: {},
        chartHeaderObject: null,
      });
    }
  };

  // Chart subgroup selection handler
  const handleSelectChartSubGroup = (subgroup) => {
    if (subgroup !== null && subgroup !== undefined) {
      const selectedDataElement = chartHeaderObject.selectedChartGroupData.find(
        (f) => f.chartSubGroup === subgroup.label
      );
      setChartHeaderObject({
        ...chartHeaderObject,
        selectedChartSubGroupLabel: subgroup.label,
        // selectedChartViewDef: selectedDataElement?.chartViewDef || null,
        selectedChartSubGroupData: selectedDataElement || {},
        selectedSubGroupTarget: selectedDataElement?.target || null,
        selectedSubGroupSearchFunc: selectedDataElement?.searchFunc || null,
        selectedSubGroupChartType: selectedDataElement?.chartType || null,
        selectedSubGroupCharts: selectedDataElement?.charts || [],
        selectedSubGroupKpis: selectedDataElement?.kpis || [],
        selectedChartViewDef: selectedDataElement?.chartViewDef || null, // Save chartViewDef
        reset: true,
      });
      // console.log(
      //   "handleSelectChartSubGroup****",
      //   selectedDataElement?.chartViewDef || null
      // );
    }
  };
  const width = 400;
  const padding = 30;
  return (
    <Paper
      sx={{
        width: isVeryLargeScreen ? `${width}px` : "100%",
        padding: "1rem",
        height: isVeryLargeScreen ? `${height}px` : null,
        borderRadius: `${BORDER_RADIUS}px`,
        display: "flex",
        flexDirection: isMobile
          ? "column"
          : isTablet
          ? "column"
          : isLargeTablet
          ? "row"
          : isLargeScreen
          ? "row"
          : "column",
      }}
      elevation={PAPER_ELEVATION}
    >
      {/* Show only first selected item */}
      <GenericAutocomplete
        multiple={false}
        dataList={allCampuses}
        changeHandler={handleSelectCampus}
        value={selectedCampus !== null ? selectedCampus : null}
        size={`${isMobile || isTablet ? "small" : "medium"}`}
        style={{
          // paddingBottom: isMobile ? '1rem' : isTablet? '1rem' : isLargeTablet ?  0 : isLargeScreen ? 0 : '1rem',
          marginBottom: isMobile
            ? "1rem"
            : isTablet
            ? "1rem"
            : isLargeTablet
            ? 0
            : isLargeScreen
            ? 0
            : "1rem",
          paddingRight: isMobile
            ? 0
            : isTablet
            ? 0
            : isLargeTablet
            ? "1rem"
            : isLargeScreen
            ? "1rem"
            : 0,
          width: isMobile
            ? "100%"
            : isTablet
            ? "100%"
            : isLargeTablet
            ? "33.3%"
            : isLargeScreen
            ? "33.3%"
            : `${width - padding}px`,
        }}
        label={t("Campus")}
      />
      <GenericAutocomplete
        dataList={(chartHeaderObject.chartData || []).map(
          (item) => item.chartGroupName
        )}
        changeHandler={handleSelectChartGroup}
        size={`${isMobile || isTablet ? "small" : "medium"}`}
        value={chartHeaderObject.selectedChartGroupLabel}
        label={t("CHARTCAT")}
        popperStyle={
          {
            // width: isMobile ? "300px" : "auto",
          }
        }
        style={{
          marginBottom: isMobile
            ? "1rem"
            : isTablet
            ? "1rem"
            : isLargeTablet
            ? 0
            : isLargeScreen
            ? 0
            : "1rem",
          paddingRight: isMobile
            ? 0
            : isTablet
            ? 0
            : isLargeTablet
            ? "1rem"
            : isLargeScreen
            ? "1rem"
            : 0,
          width: isMobile
            ? "100%"
            : isTablet
            ? "100%"
            : isLargeTablet
            ? "33.3%"
            : isLargeScreen
            ? "33.3%"
            : `${width - padding}px`,
        }}
      />

      <GenericAutocomplete
        dataList={(chartHeaderObject.selectedChartGroupData || []).map(
          (subGroup) => subGroup.chartSubGroup
        )}
        changeHandler={handleSelectChartSubGroup}
        size={`${isMobile || isTablet ? "small" : "medium"}`}
        value={chartHeaderObject.selectedChartSubGroupLabel}
        label={t("CHARTCATSUB")}
        popperStyle={
          {
            // placement: `${drawerWidth === 0 ? "left" : "bottom"}`,
            // width: `${drawerWidth === 0 ? "300px" : "auto"}`,
          }
        }
        style={{
          marginBottom: isMobile
            ? "1rem"
            : isTablet
            ? "1rem"
            : isLargeTablet
            ? 0
            : isLargeScreen
            ? 0
            : "1rem",
          paddingBottom: `${drawerWidth === 0 ? "0px" : null}`,
          width: isMobile
            ? "100%"
            : isTablet
            ? "100%"
            : isLargeTablet
            ? "33.3%"
            : isLargeScreen
            ? "33.3%"
            : `${width - padding}px`,
        }}
      />
    </Paper>
  );
};

export default ChartHeader;
