import React from "react";
import { alpha } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Drawer,
  useTheme,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MapIcon from "@mui/icons-material/Map";
import {
  HEADER_HEIGHT,
  SIDBAR_ICON_SCALE_FACTOR,
  BORDER_RADIUS_DRAWERS,
  SIDEBAR_WIDTH_SM,
  SIDEBAR_WIDTH_XL,
} from "../../constants";
import { useDrawerWidth } from "../../size";
import { useTranslation } from "react-i18next";

const SideMenu = ({ mainPage, setMainPage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme) =>
    theme.breakpoints.between("sm", "md")
  );

  const drawerWidth = useDrawerWidth();
  const theme = useTheme();
  const headerSize = parseInt(HEADER_HEIGHT);

  const staticMenuItems = () => {
    return [
      {
        id: 1,
        icon: <MapIcon fontSize="small" />,
        link: `/campuses`,
        text: t("MAP"),
      },
      {
        id: 2,
        icon: <EqualizerIcon fontSize="small" />,
        link: `/charts`,
        text: t("CHARTS"),
      },
      {
        id: 3,
        icon: <AssignmentIcon fontSize="small" />,
        link: `/tasks`,
        text: t("TASKS"),
      },
    ];
  };

  const setAndNavigate = (link, index) => {
    setMainPage(index);
    navigate(link);
  };

  const DrawerContent = ({ type }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: type === "small" ? "row-reverse" : "column",
          paddingTop: type === "small" ? "0px" : "20px",
          alignItems: type === "small" ? "center" : "left",
          //alignContent: type === "small" ? 'center' : "left",
          justifyContent: type === "small" ? "flex-start" : "flex-start",
          width: "100%",
          margin: type === "small" ? "auto 0px" : "0px auto",
        }}
      >
        {staticMenuItems().map((item) => (
          <Tooltip
            title={item.text}
            key={item.id}
            placement={isMobile ? "top" : "right"}
          >
            <IconButton
              className="sideMenu"
              sx={{
                color:
                  mainPage === item.id
                    ? theme.palette.primary.dark
                    : theme.palette.text.primary,
                border:
                  mainPage === item.id
                    ? `1px solid ${alpha(theme.palette.primary.dark, 0.3)}`
                    : `0px solid ${theme.palette.primary.dark}`,
                marginTop: type === "small" ? "0px" : "1rem",
                paddingRight:
                  type === "small"
                    ? "null"
                    : `${(1 - SIDBAR_ICON_SCALE_FACTOR) * 0.5 * headerSize}px`,
                paddingLeft:
                  type === "small"
                    ? null
                    : `${(1 - SIDBAR_ICON_SCALE_FACTOR) * 0.5 * headerSize}px`,
                marginRight: type === "small" ? "20px" : "10px",
                marginLeft: type === "small" ? "20px" : "10px",
                display: "flex",
                justifyContent: "center",
                "& .MuiSvgIcon-root": {
                  height: isMobile
                    ? `${SIDBAR_ICON_SCALE_FACTOR * headerSize}px`
                    : `${SIDBAR_ICON_SCALE_FACTOR * headerSize}px`,
                  width: isMobile
                    ? `${SIDBAR_ICON_SCALE_FACTOR * headerSize}px`
                    : `${SIDBAR_ICON_SCALE_FACTOR * headerSize}px`,
                },
              }}
              onClick={() => setAndNavigate(item.link, item.id)}
            >
              {item.icon}
              {type !== "small" && drawerWidth > 150 && (
                <Typography variant="subtitle1" className="sideMenu">
                  {item.text}
                </Typography>
              )}
            </IconButton>
          </Tooltip>
        ))}
      </Box>
    );
  };

  return (
    <Drawer
      variant="permanent" // temporary, permanent, persistent
      anchor={isMobile ? "bottom" : "left"}
      PaperProps={{
        sx: {
          height: isMobile
            ? SIDEBAR_WIDTH_SM
            : `${staticMenuItems().length * 75}px`,
          width: isMobile
            ? "100%"
            : isTablet
            ? SIDEBAR_WIDTH_SM
            : SIDEBAR_WIDTH_XL,
          borderRadius: isMobile
            ? `${BORDER_RADIUS_DRAWERS}px ${BORDER_RADIUS_DRAWERS}px 0 0`
            : `0px ${BORDER_RADIUS_DRAWERS}px ${BORDER_RADIUS_DRAWERS}px 0`,
          border: `1px solid ${alpha(theme.palette.primary.dark, 0.6)}`,
          borderLeft: isMobile ? null : "none",
          paddingTop: isMobile ? "0px" : "120px",
          marginTop: isMobile
            ? "0px"
            : `calc(${HEADER_HEIGHT} + ${isTablet ? "4.5rem" : "7rem"})`,
          padding: 0,
          boxSizing: "border-box",
          boxShadow: `2px 0px 50px ${alpha(theme.palette.primary.dark, 0.1)}`,
        },
      }}
    >
      <DrawerContent type={drawerWidth === 0 ? "small" : "normal"} />
    </Drawer>
  );
};
export default SideMenu;
