import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Tooltip,
  Typography,
  Avatar,
  ListItemAvatar,
  IconButton,
  useTheme,
  Paper,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import Workspace from "../PageStructure/Workspace";
import QuillEditor from "../Generic/QuillEditor";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import { alpha } from "@mui/material/styles";
import {
  fetchTaskComments,
  postTaskComment,
  patchTaskComment,
  deleteTaskComment,
} from "../actions/api";
import { stringAvatar } from "../Generic/genericFuncs";
import { useDrawerWidth } from "../../size";
import FavoriteDrawer from "../Generic/FavoriteDrawer";
import {
  PAPER_ELEVATION,
  BORDER_RADIUS,
  SIDEBAR_WIDTH_SM,
} from "../../constants";

function DetailTask() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { id } = useParams();

  const [task, setTask] = useState(null);
  const drawerWidth = useDrawerWidth();

  const [comments, setComments] = useState([]);
  const [notExpanded, setNotExpanded] = useState([]); // inverse logic, if value is included => not expanded
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleClose = () => {
    setDrawerOpen(false);
  };

  function toggleElementInArray(array, element) {
    const index = array.indexOf(element);
    if (index !== -1) {
      return array.filter((item) => item !== element);
    } else {
      return [...array, element];
    }
  }
  const handleAccordeonChange = (panel) => (event) => {
    const newArray = toggleElementInArray(notExpanded, panel);
    setNotExpanded([...newArray]);
  };

  useEffect(() => {
    const fetchComments = async (taskId) => {
      const data = await fetchTaskComments(taskId);
      const commentsParsed = data.comments.map((m) => {
        return { ...m, text: JSON.parse(m.text) };
      });
      const updatedData = { ...data, comments: commentsParsed };
      setTask(updatedData);
      setComments(updatedData.comments);
    };

    fetchComments(id);
  }, [id]);

  // Receive the contents of the editor, with formatting data, represented by a Delta object
  const handleCommentSubmit = async (content, commentId) => {
    const text = JSON.stringify(content);
    if (commentId === undefined) {
      let data = await postTaskComment({ text: text, task: id });
      data["text"] = JSON.parse(data.text);
      setComments([...comments, data]);
    } else {
      let data = await patchTaskComment({ text: text, task: id }, commentId);
      data["text"] = JSON.parse(data.text);
      const updatedComments = comments.map((comment) =>
        comment.id === commentId ? data : comment
      );
      setComments(updatedComments);
    }
  };

  const handleDeleteComment = async (commentId) => {
    const data = await deleteTaskComment(id, commentId);
    if (data) {
      const updatedComments = comments.filter(
        (comment) => comment.id !== commentId
      );
      setComments(updatedComments);
    } else console.error("Error deleting comment."); // TODO add generic error handling/communication
  };

  const sortedComments = comments.slice().sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at); // latest first
  });
  // TODO consider locale of user (language, country-code)
  const AccordionRows = () => {
    return (
      <Box sx={{ mt: 2 }}>
        {sortedComments.map((comment, index) => {
          // parse date
          const date = new Date(comment.created_at);
          const options = { year: "numeric", month: "2-digit", day: "2-digit" };
          const dateFormatted = date.toLocaleDateString("de-CH", options);
          return (
            <Accordion
              expanded={!notExpanded.includes(`panel${index}`)}
              elevation={PAPER_ELEVATION}
              sx={{
                borderRadius: `${BORDER_RADIUS}px`,
              }}
              key={`panel${index}`}
              onChange={handleAccordeonChange(`panel${index}`)}
            >
              <AccordionSummary
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "48px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <ListItemAvatar sx={{ minWidth: "30px" }}>
                      <Tooltip
                        title={
                          task.campus_assigned_users.find(
                            (k) => k.id === comment.user
                          ).username
                        }
                        key={`acc-tt-${index}`}
                      >
                        <Avatar
                          key={`acc-av-${index}`}
                          //sx={{ width: '20px', height: '20px' }}
                          style={{
                            width: "25px",
                            height: "25px",
                            fontSize: "0.8125rem",
                          }}
                          alt={
                            task.campus_assigned_users.find(
                              (k) => k.id === comment.user
                            ).username
                          }
                          src={`/api/avatar/${
                            task.campus_assigned_users.find(
                              (k) => k.id === comment.user
                            ).id
                          }`}
                          {...stringAvatar(
                            task.campus_assigned_users.find(
                              (k) => k.id === comment.user
                            ).username
                          )}
                        />
                      </Tooltip>
                    </ListItemAvatar>
                    {drawerWidth > 100 && (
                      <div style={{ paddingLeft: "0.2rem" }}>
                        <Typography variant="body">
                          {
                            task.campus_assigned_users.find(
                              (k) => k.id === comment.user
                            ).username
                          }
                        </Typography>
                      </div>
                    )}

                    <div style={{ paddingLeft: "1rem" }}>
                      <Typography variant="body">{dateFormatted}</Typography>
                    </div>
                  </div>
                  <div>
                    <IconButton
                      className="workspace"
                      variant="contained"
                      onClick={() => handleDeleteComment(comment.id)}
                      sx={{}}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <QuillEditor
                  existingDeltaObject={comment.text}
                  commentId={comment.id}
                  handleCommit={handleCommentSubmit}
                />
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    );
  };

  const deltaDefaultObject = {
    ops: [
      {
        insert: `${t("OBSERVATION")}\n`,
        attributes: { header: 3, color: theme.palette.primary.dark },
      },
      { insert: "\n" },
      { insert: "tdb\n" },
      { insert: "\n" },
      {
        insert: `${t("MEASURE")}\n`,
        attributes: { header: 3, color: theme.palette.primary.dark },
      },
      { insert: "\n" },
      { insert: "tbd\n" },
      { insert: "\n" },
    ],
  };

  const title = task === null ? "" : task.title;
  return (
    <Workspace
      isLoading={false}
      titleIcon={<EditNoteIcon color="primary" sx={{ fontSize: 40 }} />}
      title={`${title}`}
      //addTitleElementRightSide={<AddEntry />}
    >
      {/* Load last entry */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: "1rem",
        }}
      >
        <Typography variant="h5" component="h2" gutterBottom>
          {t("NEW_ENTRY")}
        </Typography>
        <Paper
          sx={{
            width: "100%",
            padding: "1rem",
            background: theme.palette.background.default,
            // transform: 'translateX(-50%)',
            borderRadius: `${BORDER_RADIUS}px`,
            border: `1px solid ${theme.palette.primary.dark}`,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            boxShadow: `2px 0px 50px ${alpha(theme.palette.primary.dark, 0.1)}`,
          }}
          //   elevation={PAPER_ELEVATION}
          elevation={0}
        >
          <IconButton
            id="favorite-button"
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            <BookmarksIcon />
          </IconButton>
        </Paper>
        <Paper
          elevation={PAPER_ELEVATION}
          sx={{
            width: "100%",
            p: 4,
            mt: 2,
            background: theme.palette.background.default,
            display: "flex",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <QuillEditor
              existingDeltaObject={deltaDefaultObject}
              handleCommit={handleCommentSubmit}
            />
          </Box>
        </Paper>

        <FavoriteDrawer
          isOpen={drawerOpen}
          reload={false}
          handleClose={handleClose}
        />
        <div style={{ paddingTop: "3rem", paddingBottm: "16rem" }}>
          {comments.length > 0 && (
            <>
              <Typography variant="h5" component="h2" gutterBottom>
                {t("EXISTING_ENTRY")}
              </Typography>
              <AccordionRows />
            </>
          )}
        </div>
        {/* Special block for mobile devices, do not delete */}
        <div
          style={{
            height: drawerWidth === 0 ? `${SIDEBAR_WIDTH_SM}px` : "0px",
          }}
        ></div>
      </div>
    </Workspace>
  );
}

export default DetailTask;
