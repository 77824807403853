import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Chip,
  Paper,
  Avatar,
  Stack,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Workspace from "../PageStructure/Workspace";
import TaskEditMask from "./TaskEditMask";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  TASK_GROUPS,
  TASK_PRIOS,
  TASK_STATES,
  PAPER_ELEVATION,
  SIDEBAR_WIDTH_SM,
} from "../../constants";
import { useDrawerWidth } from "../../size";
import { fetchTaskById, patchTaskMetaData } from "../actions/api";

function EditTask() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const drawerWidth = useDrawerWidth();
  const [task, setTask] = useState(() => {
    const saved = localStorage.getItem("selectedCampus");
    const obj = JSON.parse(saved);

    return {
      title: "",
      due_date: "",
      priority: { text: "", api: "", key: "" },
      status: { text: "", api: "", key: "" },
      task_group: { text: "", api: "", key: "" },
      assigned_to: [],
      campus: obj === null ? { id: "" } : obj,
    };
  });
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    const fetchTask = async (taskId) => {
      const data = await fetchTaskById(taskId);
      const updatedTask = {
        title: data.title,
        due_date: data.due_date,
        priority: TASK_PRIOS.find((k) => k.api === data.priority),
        status: TASK_STATES.find((k) => k.api === data.status),
        task_group: TASK_GROUPS.find((k) => k.api === data.task_group),
        assigned_to: data.campus_assigned_users,
        campus: { id: data.campus, name: data.campus_name },
        origData: data,
      };
      setTask(updatedTask);
    };

    fetchTask(id);
  }, [id]);

  useEffect(() => {
    if (updateSuccess) {
      const timer = setTimeout(() => navigate("/tasks"), 2000);
      return () => clearTimeout(timer);
    }
  }, [updateSuccess, navigate]);

  const handleUpdateTask = async (e, taskId) => {
    // prevent standard from transmission
    e.preventDefault();
    const mod = {
      title: task.title,
      due_date: task.due_date,
      priority: task.priority.api,
      status: task.status.api,
      task_group: task.task_group.api,
      assigned_to: task.assigned_to?.map((k) => k.id),
      campus: task.campus.id,
    };
    setUpdateSuccess(await patchTaskMetaData(taskId, mod));
  };

  return (
    <Workspace
      isLoading={false}
      titleIcon={<ModeEditIcon color="primary" sx={{ fontSize: 40 }} />}
      title={task.title}
      addTitleElementRightSide={null}
    >
      <Paper
        elevation={PAPER_ELEVATION}
        sx={{
          maxWidth: "600px",
          p: 3,
          mb: 3,
          background: theme.palette.background.default,
          display: "flex",
        }}
      >
        <Box>
          {updateSuccess && (
            <Box sx={{ my: 2, color: "success.main" }}>
              {t("Task updated successfully")}
            </Box>
          )}
          <Box sx={{ mt: 0, mb: 2 }}>
            <Typography variant="h5" fontWeight="bold" color="primary">
              Campus: {task.campus.name}
            </Typography>
            <Stack direction="row" spacing={1} sx={{ my: 2 }}>
              {task.assigned_to?.map((user) => (
                <Chip
                  key={user.id}
                  avatar={<Avatar src={`/api/avatar/${user.id}`} />}
                  label={user.username}
                />
              ))}
            </Stack>
          </Box>
          <form onSubmit={(e) => handleUpdateTask(e, id)}>
            <TaskEditMask
              val={task}
              setVal={setTask}
              buttonActionText={t("UPDATE")}
            />
          </form>
        </Box>
      </Paper>
      {/* Special block for mobile devices, do not delete */}
      <div
        style={{ height: drawerWidth === 0 ? `${SIDEBAR_WIDTH_SM}px` : "0px" }}
      ></div>
    </Workspace>
  );
}

export default EditTask;
