import React, { useState, useEffect } from "react";

import { useTheme, Paper, Stack, useMediaQuery, Chip } from "@mui/material";

import { useTranslation } from "react-i18next";
import { fetchChartPoints } from "../actions/api";
import { PAPER_ELEVATION, BORDER_RADIUS } from "../../constants";
import { getElementHeightById } from "./chartFuncs";

// Select target based on the model by clicking on a chip. The chips are sorted alphabetically. For small screens, a selector is provided
const ChartTargetSelector = ({
  sharedHeaderObject,
  label,
  objectHandler,
  value,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme) =>
    theme.breakpoints.between("sm", "md")
  );
  const isLargeTablet = useMediaQuery((theme) =>
    theme.breakpoints.between("md", "lg")
  );
  const isLargeScreen = useMediaQuery((theme) =>
    theme.breakpoints.between("lg", "xl")
  );
  const theme = useTheme();

  // Load from storage if available (refresh required)
  const [target, setTarget] = useState(() => {
    const saved = localStorage.getItem("chartTarget");
    return saved === null || saved.length === 0
      ? {
          selectedTargetId: null,
          selected: null,
          selectionName: null,
          selectedTargetCharts: [],
          selectedTargetKpis: [],
        }
      : JSON.parse(saved);
  });

  const [height, setHeight] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // Update storge
  useEffect(() => {
    // reset hash code if any
    const temp = { ...target };
    delete temp.hashCode;
    localStorage.setItem("chartTarget", JSON.stringify(temp));
  }, [target]);

  // Wait for updated data and then trigger objectHandler
  useEffect(() => {
    if (!isLoading) {
      // console.log("Triggering object handler with updated target", target);
      objectHandler({
        selectedTargetCharts: target.selectedTargetCharts,
        selectedTargetKpis: target.selectedTargetKpis,
        selectedTargetId: target.selectedTargetId,
        selectionName: target.selectionName,
      });
    }
  }, [target, objectHandler, isLoading]);

  // Reset data if chart / campus selection changes. This controlles the ChartGraphWorkspace section
  // Data loaded from links has a hash code. This prevents from setting the data back. As reading from storage is an asynch process
  //'sharedHeaderObject.targetData' might be empty when reloading
  useEffect(() => {
    if (sharedHeaderObject.targetData.length === 0 && !target.hashCode) {
      // console.log("Resetting targets------------------------------------------", target);
      objectHandler({
        selectedTargetId: null,
        selected: null,
        selectionName: null,
        selectedTargetCharts: [],
        selectedTargetKpis: [],
      });
    }
  }, [sharedHeaderObject.targetData, objectHandler, target]);

  // Size observer
  useEffect(() => {
    const observedElementId = "range-select-paper"; // from ChartDateRangeSelector
    const observedElement = document.getElementById(observedElementId);

    const updateHeight = () => {
      const newHeight = getElementHeightById(observedElementId);
      if (newHeight !== null) {
        setHeight(newHeight); // Update the height state
      }
    };

    // Initial height calculation
    updateHeight();

    // Create a ResizeObserver to monitor height changes
    const resizeObserver = new ResizeObserver(updateHeight);
    if (observedElement) {
      resizeObserver.observe(observedElement); // Start observing the element
    }

    // Cleanup function to disconnect the observer
    return () => {
      if (observedElement) {
        resizeObserver.unobserve(observedElement); // Stop observing the element
      }
    };
  }, []);

  // Api call
  const getPointListFromApi = async (
    customerID,
    selectedCampus,
    targetId,
    specs,
    chartViewDef
  ) => {
    try {
      // console.log(
      //   `Fetching chart points for targetId: ${targetId}, chartSpecs: ${specs}, chartViewDef: ${chartViewDef}, customerId: ${customerID}, campusId: ${selectedCampus}`
      // );

      const data = await fetchChartPoints(
        customerID,
        selectedCampus,
        targetId,
        specs,
        chartViewDef
      );

      // console.log(`Received data for chartViewDef ${chartViewDef}:`, data);
      if (!Array.isArray(data) || data.length === 0) {
        console.log("No data received, return empty list");
        return [];
      } else return data;
    } catch (error) {
      console.error("Error fetching chart points from API:", error);
      return [];
    }
  };

  const handleChange = async (selection, index) => {
    setIsLoading(true);
    // console.log(
    //   `Handling change for selection:`,
    //   selection,
    //   `at index ${index}`
    // );

    try {
      // Fetch data (one call for all charts)

      const chartSpecs = sharedHeaderObject.chartViewData.charts.map(
        (element) => element.spec
      );
      const chartSpecsString = chartSpecs.join(", ");

      const pointList = await getPointListFromApi(
        sharedHeaderObject.chartViewData.customerID,
        sharedHeaderObject.chartViewData.campusId,
        selection.id,
        chartSpecsString,
        sharedHeaderObject.chartViewData.chartViewDef
      );

      const chartData = chartSpecs.map((spec, index) => {
        const foundData = pointList.find(
          (chartData) => chartData.chart === spec
        );
        return {
          ...sharedHeaderObject.chartViewData.charts[index],
          title: sharedHeaderObject.chartViewData.charts[index].dis,
          spec: sharedHeaderObject.chartViewData.charts[index].spec,
          targetId: selection.id,
          dataSeries: foundData ? foundData.data : [],
          colors: null, // remove
          seriesTypes: null, // remove
        };
      });

      setTarget((prevTarget) => ({
        ...prevTarget,
        selectedTargetId: selection.id,
        selectionName: selection.navName,
        selected: index,
        selectedTargetCharts: chartData,
      }));
    } catch (error) {
      console.error("Error during handleChange:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const heightMobile = 150;
  const padding = 30;
  return (
    <Paper
      sx={{
        width: "100%",
        padding: `${padding / 2}px`,
        height: isMobile
          ? `${heightMobile}px`
          : isTablet
          ? `${heightMobile + 50}px`
          : isLargeTablet
          ? `${heightMobile + 50}px`
          : isLargeScreen
          ? `${heightMobile + 50}px`
          : `${height}px`,
        borderRadius: `${BORDER_RADIUS}px`,
        overflowX: "hidden",
      }}
      elevation={PAPER_ELEVATION}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
          justifyContent: "flex-start",
          height: "100%",
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            maxHeight: isMobile
              ? `${heightMobile - padding}px`
              : `${height - padding}px`,
            overflowY: "auto",
            overflowX: "auto",
          }}
          useFlexGap
          flexWrap="wrap"
        >
          {sharedHeaderObject.targetData &&
          sharedHeaderObject.targetData.length > 0 ? (
            sharedHeaderObject.targetData.map((obj, index) => (
              <Chip
                key={index}
                clickable={true}
                style={{
                  backgroundColor:
                    target.selectedTargetId === obj.id
                      ? theme.palette.secondary.dark
                      : theme.palette.secondary.main,
                }}
                label={obj.navName}
                onClick={() => handleChange(obj, index)}
              />
            ))
          ) : (
            <div
              style={{ padding: "16px", color: theme.palette.text.secondary }}
            >
              {t("NO_DATA")}
            </div>
          )}
        </Stack>
      </div>
    </Paper>
  );
};

export default ChartTargetSelector;
