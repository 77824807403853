import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  AvatarGroup,
  Avatar,
  useTheme,
  Chip,
  Menu,
  MenuItem,
  Link,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDrawerWidth } from "../../size";
import { PAPER_ELEVATION, SIDEBAR_WIDTH_SM } from "../../constants";
import { stringAvatar } from "../Generic/genericFuncs";

import { visuallyHidden } from "@mui/utils";
import { useTranslation } from "react-i18next";

// SORTING FUNCTIONS (TODO: Move to genericFuncs)

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function descendingDateComparator(a, b, orderBy) {
  const aa = new Date(a[orderBy]).toLocaleDateString();
  const bb = new Date(b[orderBy]).toLocaleDateString();
  if (bb < aa) {
    return -1;
  }
  if (bb > aa) {
    return 1;
  }
  return 0;
}

function descendingAssigneeComparator(a, b) {
  // filter on first assigne
  const aa = a.campus_assigned_users[0].username;
  const bb = b.campus_assigned_users[0].username;
  if (bb < aa) {
    return -1;
  }
  if (bb > aa) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  if (orderBy === "dueDate") {
    return order === "desc"
      ? (a, b) => descendingDateComparator(a, b, orderBy)
      : (a, b) => -descendingDateComparator(a, b, orderBy);
  } else if (orderBy === "priority" || orderBy === "status") {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  } else if (orderBy === "assignee") {
    return order === "desc"
      ? (a, b) => descendingAssigneeComparator(a, b)
      : (a, b) => -descendingAssigneeComparator(a, b);
  } else {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
}

// Sorter with filtered selection, args: Data, comparater: function, key
function stableSortTopics(array, comparator, selection) {
  // filter on task_group selection
  const selectionKeys = selection.map((m) => m.api);

  const filteredArray = array.filter((row) =>
    selectionKeys.includes(row.task_group)
  );

  const stabilizedThis = filteredArray.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// HANDLING FUNCTIONS

// HEADER FUNCTIONS

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left" //{headCell.date ? 'left' : 'right'}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              display: headCell.display,
              width: headCell.width,
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, selected, selectedRows } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleMenuClickNavigate = (event, link, optionalData) => {
    event.preventDefault();
    // attention: data must be serializable
    navigate(link, { state: optionalData });
  };
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {`${numSelected} ${t("SELECTED")}`}
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {t("TASK_TABLE_TITLE")}
        </Typography>
      )}

      {numSelected > 0 ? (
        <>
          <Tooltip title="Print">
            <IconButton
              component={Link}
              to={`/tasks/report`}
              onClick={(event) =>
                handleMenuClickNavigate(event, `/tasks/report`, {
                  apiFunc: "tbd",
                  data: selectedRows,
                  targetLink: "/tasks",
                })
              }
            >
              <PictureAsPdfIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("Delete")}>
            <IconButton
              component={Link}
              to={`/tasks/delete`}
              onClick={(event) =>
                handleMenuClickNavigate(event, `/tasks/delete`, {
                  apiFunc: "deleteTask",
                  argList: selected,
                  data: selectedRows,
                  dataKey: "title",
                  targetLink: "/tasks",
                })
              }
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        // <Tooltip title="Filter list">
        //   <IconButton>
        //     <FilterListIcon />
        //   </IconButton>
        // </Tooltip>
        <></>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selected: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default function TaskTable({ rows, topicSelection }) {
  const drawerWidth = useDrawerWidth();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("title");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [visibleRows, setVisibleRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState({ anchor: null, taskId: null });
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  // Sorter for topic selection, ok
  useEffect(() => {
    const sorted = stableSortTopics(
      rows,
      getComparator(order, orderBy),
      topicSelection
    ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    setVisibleRows(sorted);
    // }
  }, [topicSelection, rows, page, rowsPerPage, order, orderBy]);

  // TODO: Add Campus in case, multiple campuses are selected
  const headCells = [
    {
      id: "title",
      type: "text",
      disablePadding: true,
      label: t("TITLE"),
      display: "table-cell",
      width: null,
    },
    {
      id: "due_date",
      type: "dat",
      disablePadding: false,
      label: t("DUEDATE"),
      display: drawerWidth === 0 ? "none" : "table-cell",
      width: "100px",
    },
    {
      id: "priority",
      type: "chip",
      disablePadding: false,
      label: t("PRIO"),
      display: drawerWidth === 0 ? "none" : "table-cell",
      width: "100px",
    },
    {
      id: "status",
      type: "chip",
      disablePadding: false,
      label: t("STATUS"),
      display: drawerWidth === 0 ? "none" : "table-cell",
      width: "100px",
    },
    {
      id: "assignee",
      type: "list",
      disablePadding: false,
      label: t("ASSIGNEE"),
      display: drawerWidth === 0 ? "none" : "table-cell",
      width: "100px",
    },
    {
      id: "action",
      type: "action",
      disablePadding: false,
      display: "table-cell",
      width: "60px",
      //label: t("ACTION"),
    },
  ];

  const handleClickRowAction = (event, taskId) => {
    // Setting both the anchor for MUI Menu and the task ID
    setAnchorEl({ anchor: event.currentTarget, taskId: taskId });
  };
  const handleCloseRowAction = () => {
    setAnchorEl({ anchor: null, taskId: null });
  };

  const handleMenuClickNavigate = (event, link, optionalData) => {
    event.preventDefault();
    // attention: data must be serializable
    navigate(link, { state: optionalData });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const renderPriorityChip = (priority) => {
    // Define color mapping directly with theme references
    const chipColors = {
      High: {
        background: theme.palette.error.main,
        text: theme.palette.error.contrastText,
      },
      Medium: {
        background: theme.palette.warning.main,
        text: theme.palette.warning.contrastText,
      },
      Low: {
        background: theme.palette.success.main,
        text: theme.palette.success.contrastText,
      },
    };
    return (
      <Chip
        label={priority}
        size="small"
        sx={{
          color: chipColors[priority].text,
          background: chipColors[priority].background,
        }}
      />
    );
  };
  const renderStatusChip = (status) => {
    const chipColors = {
      Open: "info", //to define
      "In-Progress": "secondary", //to define
      Done: "success", //to define
      Cancel: "warning", //to define
      "Re-Open": "warning", //to define
    };
    // variants: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning",

    return <Chip label={status} size="small" color={chipColors[status]} />;
  };
  const renderActions = (task) => {
    // Define color mapping directly with theme references

    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls={`task-menu-${task.id}`}
          aria-haspopup="true"
          onClick={(event) => handleClickRowAction(event, task.id)}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id={`task-menu-${task.id}`}
          anchorEl={anchorEl.anchor}
          keepMounted
          open={Boolean(anchorEl.anchor) && anchorEl.taskId === task.id}
          onClose={handleCloseRowAction}
        >
          <MenuItem
            component={Link}
            to={`/tasks/${task.id}/detail`}
            onClick={(event) =>
              handleMenuClickNavigate(event, `/tasks/${task.id}/detail`)
            }
          >
            {t("Comment")}
          </MenuItem>
          <MenuItem
            component={Link}
            to={`/tasks/${task.id}/edit`}
            onClick={(event) =>
              handleMenuClickNavigate(event, `/tasks/${task.id}/edit`)
            }
          >
            {t("Edit")}
          </MenuItem>
          <MenuItem
            component={Link}
            to={`/tasks/delete`}
            onClick={(event) =>
              handleMenuClickNavigate(event, `/tasks/delete`, {
                apiFunc: "deleteTask",
                argList: [task.id],
                data: [task],
                dataKey: "title",
                targetLink: "/tasks",
              })
            }
          >
            {t("Delete")}
          </MenuItem>
        </Menu>
      </>
    );
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const labelDisplayedRows = ({ from, to, count }) => {
    return `${from}–${to} ${t("OF")} ${
      count !== -1 ? count : `${t("MORE_THAN")} ${to}`
    }`;
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper
          elevation={PAPER_ELEVATION}
          sx={{
            width: "100%",
            mb: 2,
            mt: 2,
            background: theme.palette.background.default,
          }}
        >
          <EnhancedTableToolbar
            numSelected={selected.length}
            selected={selected}
            selectedRows={rows.filter((row) => selected.includes(row.id))}
          />
          <TableContainer>
            <Table
              //sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="small" //{dense ? 'small' : 'small'}
              stickyHeader
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={headCells}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      //hover
                      // with onClicK: edit

                      role="checkbox"
                      //aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      //selected={isItemSelected}
                      //sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          onClick={(event) => handleClick(event, row.id)}
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{
                          cursor: "pointer",
                          minWidth: "100px",
                          maxWidth: drawerWidth === 0 ? "180px" : "300px",
                          overflowX: "auto",
                        }}
                        style={{ fontWeight: "800" }}
                        onClick={(event) =>
                          handleMenuClickNavigate(
                            event,
                            `/tasks/${row.id}/detail`
                          )
                        }
                      >
                        {row.title}
                      </TableCell>
                      <TableCell
                        sx={{
                          cursor: "pointer",

                          //width: drawerWidth === 0 ? '0px' : '150px',
                          display: drawerWidth === 0 ? "none" : "table-cell",
                        }}
                        onClick={(event) =>
                          handleMenuClickNavigate(
                            event,
                            `/tasks/${row.id}/detail`
                          )
                        }
                      >
                        {" "}
                        {new Date(row.due_date).toLocaleDateString()}
                      </TableCell>
                      <TableCell
                        sx={{
                          cursor: "pointer",
                          display: drawerWidth === 0 ? "none" : "table-cell",
                        }}
                        onClick={(event) =>
                          handleMenuClickNavigate(
                            event,
                            `/tasks/${row.id}/detail`
                          )
                        }
                      >
                        {renderPriorityChip(row.priority)}
                      </TableCell>
                      <TableCell
                        sx={{
                          cursor: "pointer",
                          //width: drawerWidth === 0 ? '120px' : '120px',
                          display: drawerWidth === 0 ? "none" : "table-cell",
                        }}
                        onClick={(event) =>
                          handleMenuClickNavigate(
                            event,
                            `/tasks/${row.id}/detail`
                          )
                        }
                      >
                        {" "}
                        {renderStatusChip(row.status)}
                      </TableCell>
                      <TableCell
                        sx={{
                          cursor: "pointer",
                          display: drawerWidth === 0 ? "none" : "table-cell",
                        }}
                        onClick={(event) =>
                          handleMenuClickNavigate(
                            event,
                            `/tasks/${row.id}/detail`
                          )
                        }
                      >
                        <AvatarGroup
                          max={12}
                          variant="circular"
                          sx={{
                            flexDirection: "row",
                          }}
                        >
                          {row.campus_assigned_users.map((user, index) => (
                            <Tooltip title={user.username} key={index}>
                              <Avatar
                                key={index}
                                //sx={{ width: '20px', height: '20px' }}
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  fontSize: "0.8125rem",
                                }}
                                alt={user.username}
                                src={`/api/avatar/${user.id}`}
                                {...stringAvatar(user.username)}
                              />
                            </Tooltip>
                          ))}
                        </AvatarGroup>
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "60px",
                          display: "table-cell",
                        }}
                        align="right"
                      >
                        {" "}
                        {renderActions(row)}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 33 * emptyRows, //(dense ? 33 : 33) * emptyRows, // keep it dense, was 53
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            sx={{
              "& .MuiTablePagination-spacer": {
                flexBasis: drawerWidth === 0 ? "0%" : "100%", // No impact
              },
            }}
            component="div"
            count={rows.length}
            labelRowsPerPage={drawerWidth === 0 ? "" : t("ROWS_PER_PAGE")}
            labelDisplayedRows={labelDisplayedRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      {/* Special block for mobile devices, do not delete */}
      <div
        style={{ height: drawerWidth === 0 ? `${SIDEBAR_WIDTH_SM}px` : "0px" }}
      ></div>
    </>
  );
}
