import React, { useState, useRef } from 'react';
import Editor from './Editor';

const QuillEditor = ({ existingDeltaObject, handleCommit, commentId }) => {

    // eslint-disable-next-line no-unused-vars
    const [state, setState] = useState()
    // eslint-disable-next-line no-unused-vars
    const [range, setRange] = useState();
    const quillRef = useRef();

    const handleButtonInternal = (e, state) => {
        const q = quillRef.current

        handleCommit(q.getContents(), commentId)
        // load default after timeout (alternative: add useEffect for existingDeltaObject)
        setTimeout(() => {
            q.setContents(existingDeltaObject.ops)

        }, 500);
    }

    return (
        <Editor
            ref={quillRef}
            defaultValue={existingDeltaObject}
            onSelectionChange={setRange}
            onTextChange={setState}
            ro={commentId === undefined ? false : true}
            buttonHandler={handleButtonInternal}
        />
    )
}
export default QuillEditor;