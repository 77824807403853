import React from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  Chip,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  TASK_GROUPS,
  TASK_PRIOS,
  TASK_STATES,
  hotTranslate,
} from "../../constants";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

const getUsernamesByIds = (campusUsers, selectedUserIds) => {
  return campusUsers
    .filter((user) => selectedUserIds.includes(user.id))
    .map((user) => user.username);
};

function TaskEditMask({
  val,
  setVal,
  campuses,
  campusUsers,
  buttonActionText,
  allowAssign,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate("/tasks");
  };

  // console.log("task", val.due_date, val)
  return (
    <Box>
      {/* Form fields for title, due_date, priority */}
      <TextField
        label={t("TITLE")}
        value={val.title}
        onChange={(e) => setVal({ ...val, title: e.target.value })}
        fullWidth
        margin="dense"
        required
        size="small"
        id="title"
      />
      <TextField
        label={t("DUEDATE")}
        type="datetime-local"
        //value={val.due_date.split("T")[0]}
        value={val.due_date.replace("Z", "").substring(0, 16)}
        onChange={(e) => setVal({ ...val, due_date: e.target.value })}
        fullWidth
        margin="normal"
        InputLabelProps={{ shrink: true }}
        id="duedate"
        required
      />
      <FormControl id="prio" fullWidth margin="normal">
        <InputLabel margin={"dense"} size="small">
          {t("PRIO")}
        </InputLabel>
        <Select
          value={val.priority.api}
          label={t("PRIO")}
          onChange={(e) =>
            setVal({
              ...val,
              priority: TASK_PRIOS.find((k) => k.api === e.target.value),
            })
          }
          required
        >
          {hotTranslate(TASK_PRIOS).map((el) => (
            <MenuItem key={el.key} value={el.api}>
              {el.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl id="state" fullWidth margin="normal">
        <InputLabel margin={"dense"} size="small">
          {t("STATUS")}
        </InputLabel>
        <Select
          value={val.status.api}
          label={t("STATUS")}
          onChange={(e) =>
            setVal({
              ...val,
              status: TASK_STATES.find((k) => k.api === e.target.value),
            })
          }
          required
        >
          {hotTranslate(TASK_STATES).map((el) => (
            <MenuItem key={el.key} value={el.api}>
              {el.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl id="group" fullWidth margin="normal">
        <InputLabel margin={"dense"} size="small">
          {t("TASKCAT")}
        </InputLabel>
        <Select
          value={val.task_group.api}
          label={t("TASKCAT")}
          onChange={(e) =>
            setVal({
              ...val,
              task_group: TASK_GROUPS.find((k) => k.api === e.target.value),
            })
          }
          required
        >
          {hotTranslate(TASK_GROUPS).map((el) => (
            <MenuItem key={el.key} value={el.api}>
              {el.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {campuses !== undefined && (
        <FormControl id="campus" fullWidth margin="normal">
          <InputLabel margin={"dense"} size="small">
            {t("Campus")}
          </InputLabel>
          <Select
            label="Campus"
            value={val.campus.id || ""}
            onChange={(e) =>
              setVal({
                ...val,
                campus: campuses.find((f) => f.id === e.target.value),
              })
            }
            required
          >
            {campuses.map((campus) => (
              <MenuItem key={campus.id} value={campus.id}>
                {campus.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {campusUsers !== undefined && (
        <FormControl id="user" fullWidth margin="normal">
          <InputLabel margin={"dense"} size="small" id="assigned-to-label">
            {t("Assigned To")}
          </InputLabel>
          <Select
            multiple
            disabled={!allowAssign}
            value={val.assigned_to}
            onChange={(e) => setVal({ ...val, assigned_to: e.target.value })}
            input={
              <OutlinedInput id="select-multiple-chip" label="Assigned To" />
            }
            renderValue={(selected) => (
              <div>
                {getUsernamesByIds(campusUsers, selected).map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </div>
            )}
          >
            {campusUsers.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.username}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button
          className="workspace"
          variant="contained"
          startIcon={<AddIcon />}
          type="submit"
          sx={{ marginTop: "1rem" }}
        >
          {buttonActionText}
        </Button>
        <Button
          className="workspace"
          variant="contained"
          onClick={handleCancel}
          startIcon={<CloseIcon />}
          sx={{ marginTop: "1rem", marginLeft: "8px" }}
        >
          {t("RETURN")}
        </Button>
      </div>
    </Box>
  );
}

export default TaskEditMask;
