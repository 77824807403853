import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Paper,
  Typography,
  Grid,
  Button,
  CircularProgress,
  Avatar,
  TextField,
  Card,
  CardContent,
  List,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TableContainer,
  colors,
  Box,
  useTheme,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email"; // Import the email icon
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";

function Settings() {
  const { t } = useTranslation();
  const theme = useTheme(); // Using the theme to apply styles
  const [userProfile, setUserProfile] = useState({
    first_name: "",
    family_name: "",
    company: "",
    email: "",
    phone: "",
  });
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token not found. Redirect to the login page.");
      // Handle redirection to login page here
      return;
    }

    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    // Fetch user profile and customer data concurrently
    Promise.all([
      axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/customers/userprofile/detail/`,
        config
      ),
      axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/accounts/get-user-info/`,
        config
      ),
    ])
      .then(([userProfileResponse, customerResponse]) => {
        if (userProfileResponse.status === 200) {
          setUserProfile(userProfileResponse.data);
        }

        if (customerResponse.status === 200) {
          const user_id = customerResponse.data.user_id;
          // const user_name = customerResponse.data.user_name;
          // console.log("user_id:", user_id);
          // console.log("user_name:", user_name);

          // Fetch customer information for the logged-in user
          axios
            .get(
              `${process.env.REACT_APP_API_BASE_URL}/api/customers/${user_id}/customer/`,
              config
            )
            .then((customerDetailsResponse) => {
              if (
                customerDetailsResponse.status === 200 &&
                Array.isArray(customerDetailsResponse.data)
              ) {
                setCustomer(customerDetailsResponse.data);

                // Handle multiple customers logic if needed
                if (customerDetailsResponse.data.length > 1) {
                  // Implement logic to handle multiple customers
                } else {
                  // Handle single customer logic
                }
              } else {
                console.error("Error fetching customer details.");
              }
            })
            .catch((error) => {
              console.error("Error fetching customer details:", error);
            });
        } else {
          console.error("Error fetching user data.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []); // Empty dependency array to run effect only once

  const handleInputChange = (e) => {
    setUserProfile({ ...userProfile, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    };

    const updateData = {
      first_name: userProfile.first_name,
      family_name: userProfile.family_name,
      company: userProfile.company,
      email: userProfile.email,
      phone: userProfile.phone,
    };

    axios
      .patch(
        `${process.env.REACT_APP_API_BASE_URL}/api/customers/userprofile/detail/`,
        updateData,
        config
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("Profile updated successfully!");
        }
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
      });
  };

  // Function to display each customer's information
  const renderCustomerInfo = (customer) => (
    <Grid container spacing={4} key={customer.id}>
      {" "}
      {/* Increased spacing between sections */}
      <Grid item xs={12} md={4} lg={3}>
        {" "}
        {/* Adjust card widths for larger screens */}
        <Card elevation={2} sx={{ p: 4, mb: 4 }}>
          {" "}
          {/* Wider padding for visual clarity */}
          <CardContent>
            <Typography
              variant="h5"
              color="textPrimary"
              gutterBottom
              component="div"
            >
              {customer.name}
            </Typography>
            <Avatar
              src={customer.customer_icon}
              alt={customer.name}
              sx={{ width: 200, height: 48, my: 2 }}
            />
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Customer ID: {customer.customer_id}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={8} lg={9}>
        <Card elevation={2} sx={{ p: 2, mb: 2 }}>
          <CardContent>
            <Typography
              variant="h5"
              gutterBottom
              fontWeight="bold"
              sx={{ textAlign: "center" }}
            >
              Campuses
            </Typography>

            <Grid container spacing={3}>
              {" "}
              {/* Increased spacing for better visual separation */}
              {customer.campuses.map((campus) => (
                <Grid item key={campus.id} xs={12} sm={3} md={12}>
                  {" "}
                  {/* Adjusted card widths */}
                  <Card elevation={3} sx={{ p: 4, mb: 4 }}>
                    {" "}
                    {/* Increased elevation for depth */}
                    <CardContent>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Avatar
                            src={campus.campus_image}
                            alt={campus.name}
                            sx={{ width: 100, height: 100 }}
                          />{" "}
                          {/* Added campus image */}
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Typography
                            variant="h5"
                            gutterBottom
                            fontWeight="bold"
                          >
                            {campus.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            gutterBottom
                            color="textSecondary"
                            sx={{ fontStyle: "italic" }}
                          >
                            {campus.city}, {campus.country}
                          </Typography>
                          <Typography
                            variant="body2"
                            gutterBottom
                            color="textSecondary"
                            sx={{ fontStyle: "italic" }}
                          >
                            {campus.campus_type}
                          </Typography>
                        </Grid>
                      </Grid>
                      <br />
                      {/* Associated Users-Customer(Level) */}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                      >
                        Customer Access Permissions:
                        <Tooltip title="Users added to this group are considered as part of the customer group. However, they do not have access to the Campuses. It is necessary to assign the campuses to these users separately.">
                          <InfoIcon
                            sx={{
                              fontSize: 16,
                              marginLeft: 1,
                              marginBottom: "-0.25em",
                            }}
                          />
                        </Tooltip>
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 2,
                          alignItems: "center",
                        }}
                      >
                        {customer.user_emails &&
                          customer.user_emails.map((email, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <Avatar sx={{ width: 24, height: 24 }}>
                                <EmailIcon /> {/* Email icon in the avatar */}
                              </Avatar>
                              <Typography
                                variant="body2"
                                sx={{ fontSize: "12px" }}
                              >
                                {email} {/* Display email */}
                              </Typography>
                            </Box>
                          ))}
                      </Box>
                      <hr />
                      {/* Associated Users-Campus(Level) */}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                      >
                        Customer's Campus Access Permissions:
                        <Tooltip title="To gain access to Campuses, users must be members of both the customer group and possess the appropriate campus group permission level.">
                          <InfoIcon
                            sx={{
                              fontSize: 16,
                              marginLeft: 1,
                              marginBottom: "-0.25em",
                            }}
                          />
                        </Tooltip>
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 2,
                          alignItems: "center",
                        }}
                      >
                        {campus.assigned_users &&
                          campus.assigned_users.map((user, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <Avatar sx={{ width: 24, height: 24 }}>
                                <EmailIcon /> {/* Email icon in the avatar */}
                              </Avatar>
                              <Typography
                                variant="body2"
                                sx={{ fontSize: "12px" }}
                              >
                                {user.email} {/* Display user's email */}
                              </Typography>
                            </Box>
                          ))}
                      </Box>
                      {/* <br /> }

                      {/* Visual divider between campus info and sites */}
                      <Typography
                        variant="body2"
                        gutterBottom
                        fontWeight="bold"
                      >
                        Sites
                      </Typography>
                      <List dense>
                        {campus.sites.map((site) => (
                          <TableContainer
                            component={Paper}
                            sx={{ overflowX: "auto" }}
                          >
                            {" "}
                            {/* Enable horizontal scrolling if needed */}
                            <Table sx={{ minWidth: 650 }} size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell sx={{ fontStyle: "italic" }}>
                                    Site Name
                                  </TableCell>
                                  <TableCell sx={{ fontStyle: "italic" }}>
                                    Address
                                  </TableCell>
                                  <TableCell sx={{ fontStyle: "italic" }}>
                                    Contact Info
                                  </TableCell>
                                  <TableCell sx={{ fontStyle: "italic" }}>
                                    Site Type
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow key={site.id}>
                                  <TableCell component="th" scope="row">
                                    {" "}
                                    {/* Use "th" for wrapping text */}
                                    {site.name}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {site.address}
                                  </TableCell>
                                  <TableCell
                                    sx={{ color: colors.grey }}
                                    component="th"
                                    scope="row"
                                  >
                                    {site.contact_info}
                                  </TableCell>
                                  <TableCell
                                    sx={{ color: colors.grey }}
                                    component="th"
                                    scope="row"
                                  >
                                    {site.site_type}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        ))}
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Settings
      </Typography>

      <Grid container spacing={2} justifyContent="space-between">
        {" "}
        {/* Space buttons evenly */}
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/campuses"
          >
            BACK
          </Button>
        </Grid>
        <Grid item>
          <Link to="/change-password">
            <Button variant="contained" color="warning">
              change-password
            </Button>
          </Link>
        </Grid>
      </Grid>

      <form onSubmit={handleSubmit}>
        {/* User Profile form fields */}
        <TextField
          label="First Name"
          name="first_name"
          value={userProfile.first_name}
          onChange={handleInputChange}
          margin="normal"
          fullWidth
        />
        <TextField
          label="Family Name"
          name="family_name"
          value={userProfile.family_name}
          onChange={handleInputChange}
          margin="normal"
          fullWidth
        />
        <TextField
          label="Company"
          name="company"
          value={userProfile.company}
          onChange={handleInputChange}
          margin="normal"
          fullWidth
        />
        <TextField
          label="Email"
          name="email"
          value={userProfile.email}
          onChange={handleInputChange}
          margin="normal"
          fullWidth
        />
        <TextField
          label="Contact"
          name="phone"
          value={userProfile.phone}
          onChange={handleInputChange}
          margin="normal"
          fullWidth
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ margin: "16px" }}
        >
          Save Profile Changes
        </Button>
      </form>
      <br />
      {/* Render customer information */}
      {customer && customer.map(renderCustomerInfo)}
    </Container>
  );
}

export default Settings;
