import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import {
  SIDEBAR_WIDTH_SM,
  SIDEBAR_WIDTH_MD,
  SIDEBAR_WIDTH_LG,
  SIDEBAR_WIDTH_XL,
} from "./constants";

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenSize;
};

export const useDrawerWidth = () => {
  const [drawerWidth, setDrawerWidth] = useState(0);
  const theme = useTheme();
  useEffect(() => {
    const handleResize = () => {
      let newDrawerWidth = 0; // Default drawer width

      const windowWidth = window.innerWidth;

      if (windowWidth >= theme.breakpoints.values.sm) {
        newDrawerWidth = SIDEBAR_WIDTH_SM; // Set drawer width for small screens and up
      }

      if (windowWidth >= theme.breakpoints.values.md) {
        newDrawerWidth = SIDEBAR_WIDTH_MD; // Set drawer width for medium screens and up
      }

      if (windowWidth >= theme.breakpoints.values.lg) {
        newDrawerWidth = SIDEBAR_WIDTH_LG; // Set drawer width for large screens and up
      }

      if (windowWidth >= theme.breakpoints.values.xl) {
        newDrawerWidth = SIDEBAR_WIDTH_XL; // Set drawer width for extra large screens
      }

      setDrawerWidth(newDrawerWidth);
      //console.log("Width:",newDrawerWidth)
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    handleResize();
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [theme.breakpoints]);

  return drawerWidth;
};
// Get multiple values for form
export const useFormSize = () => {
  const theme = useTheme();
  const [iconSize, setIconSize] = useState("medium");
  const [fontSize, setFontSize] = useState("0.625rem");
  useEffect(() => {
    const handleResize = () => {
      let newIconSize = "medium";
      let newFontSize = "0.825rem";

      const windowWidth = window.innerWidth;

      if (windowWidth >= theme.breakpoints.values.sm) {
        newIconSize = "medium"; // Set drawer width for small screens and up
        newFontSize = "0.825rem";
      }

      if (windowWidth >= theme.breakpoints.values.md) {
        newIconSize = "large"; // Set drawer width for medium screens and up
        newFontSize = "1.1rem";
      }

      if (windowWidth >= theme.breakpoints.values.lg) {
        newIconSize = "large"; // Set drawer width for large screens and up
        newFontSize = "1.1rem";
      }

      if (windowWidth >= theme.breakpoints.values.xl) {
        newIconSize = "large"; // Set drawer width for extra large screens
        newFontSize = "1.1rem";
      }

      setIconSize(newIconSize);
      setFontSize(newFontSize);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    handleResize();
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [theme.breakpoints]);

  return {
    fontSize: {
      xs: theme.typography.pxToRem(36), // Define the font size for extra small screens
      sm: theme.typography.pxToRem(48), // Define the font size for small screens
      md: theme.typography.pxToRem(48), // Define the font size for medium screens
      lg: theme.typography.pxToRem(48), // Define the font size for large screens
      xl: theme.typography.pxToRem(48), // Define the font size for extra large screens
    },
    fontSizeInput: {
      xs: theme.typography.pxToRem(12), // Define the font size for extra small screens
      sm: theme.typography.pxToRem(14), // Define the font size for small screens
      md: theme.typography.pxToRem(14), // Define the font size for medium screens
      lg: theme.typography.pxToRem(14), // Define the font size for large screens
      xl: theme.typography.pxToRem(14), // Define the font size for extra large screens
    },
    fontSizeField: fontSize,
    letterSpacingInput: {
      xs: "0.1rem",
      sm: "0.1rem",
      md: "0.2rem",
      lg: "0.2rem",
      xl: "0.2rem",
    },
    inputIconSize: iconSize,
    panelWidth: {
      xs: "320px",
      sm: "350px",
      md: "400px",
      lg: "450px",
      xl: "450px",
    },
    visibilityDecoration: {
      xs: "hidden",
      sm: "hidden",
      md: "visible",
      lg: "visible",
      xl: "visible",
    },
    heightDecoration: {
      xs: "0px",
      sm: "0px",
      md: "auto",
      lg: "auto",
      xl: "auto",
    },
    paddingForm: {
      xs: "8px",
      sm: "10px",
      md: "10px",
      lg: "10px",
      xl: "10px",
    },
  };
};
// Get multiple values for different elements
export const useElementSize = () => {
  const theme = useTheme();
  const [colorHeight, setColorHeight] = useState("150px");
  const [fontSize, setFontSize] = useState("0.625rem");
  useEffect(() => {
    const handleResize = () => {
      let new1 = "150px";
      let newFontSize = "0.825rem";

      const windowWidth = window.innerWidth;

      if (windowWidth >= theme.breakpoints.values.sm) {
        new1 = "150px"; // Set drawer width for small screens and up
        newFontSize = "0.825rem";
      }

      if (windowWidth >= theme.breakpoints.values.md) {
        new1 = "200px"; // Set drawer width for medium screens and up
        newFontSize = "1.1rem";
      }

      if (windowWidth >= theme.breakpoints.values.lg) {
        new1 = "200px"; // Set drawer width for large screens and up
        newFontSize = "1.1rem";
      }

      if (windowWidth >= theme.breakpoints.values.xl) {
        new1 = "200px"; // Set drawer width for extra large screens
        newFontSize = "1.1rem";
      }

      setColorHeight(new1);
      setFontSize(newFontSize);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    handleResize();
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [theme.breakpoints]);

  return { colorHeight: colorHeight, fontSize: fontSize };
};
