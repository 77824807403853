import React from "react";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material";

const TaskTopicFilter = ({ label, tasks, topicHandler, selectedTopics }) => {
  const theme = useTheme();

  const handleChange = (event) => {
    if ("text" in event) {
      const found = selectedTopics.filter((f) => f.key === event.key);
      if (found.length === 0) {
        topicHandler([...selectedTopics, event]);
      } else {
        topicHandler(selectedTopics.filter((f) => f.key !== event.key));
      }
    }
  };

  // return if no data
  if (
    (tasks === undefined || tasks === null) &&
    (selectedTopics === undefined || selectedTopics === null)
  )
    return null;

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{ paddingBottom: "1rem", mt: 2 }}
      useFlexGap
      flexWrap="wrap"
    >
      {tasks
        .sort((a, b) => a.text.localeCompare(b.text))
        .map((group, index) => (
          <Chip
            key={index}
            style={{
              color:
                selectedTopics.filter((f) => f.key === group.key).length > 0
                  ? theme.palette.secondary.contrastText
                  : "initial",
              backgroundColor:
                selectedTopics.filter((f) => f.key === group.key).length > 0
                  ? theme.palette.secondary.main
                  : "initial",
            }}
            label={group.text}
            onClick={() => handleChange(group)}
          />
        ))}
    </Stack>
  );
};

export default TaskTopicFilter;
