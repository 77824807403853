import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from "react-router-dom";
import {
    IconButton,
    TextField,
    useMediaQuery,
    useTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Button,
    DialogTitle,
    Typography,
    Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from "react-i18next";
import { alpha } from '@mui/material/styles';
import {
    fetchChartLink,
    fetchMyLinks,
    fetchUserAccessibleCampuses,
    deleteChartLink,
} from "../actions/api";
import { HEADER_HEIGHT, BORDER_RADIUS_DRAWERS } from "../../constants";
import { clearUrlParameters } from "../Generic/genericFuncs";
import { useNotification } from '../Generic/NotificationContext';

const FavoriteDrawer = (props) => {

    const { isOpen, children, handleClose, reload } = props
    const { addNotification, addError } = useNotification();
    const location = useLocation();
    const [linkLoading, setLinkLoading] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteLinkHashCode, setDeleteLinkHashCode] = useState(null);
    const [myLinks, setMyLinks] = useState([]);

    const { t } = useTranslation();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
    const isTablet = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'))

    const theme = useTheme()

    const fetchCampuses = useCallback(async () => {
        try {
            const data = await fetchUserAccessibleCampuses();
            if (data?.length > 0) {
                return data;
            } else {
                addError(t("No campuses available for this user."))
                return null
            }
        } catch (error) {
            addError(t("Error fetching campuses data."))
            return null
        } finally {

        }
    },[addError,t])

    const loadFavorite = (hash, index) => {
        // write to storage and reload chart page
        handleRetrieveLink(hash, index)
    }

    const handleRetrieveLink = useCallback(
        async (hashCode, index = null) => {

            if (linkLoading) return;

            setLinkLoading(true);

            try {
                const response = await fetchChartLink(hashCode);
                if (response?.success) {
                    const { page_data } = response.data;
                    // For charts: continue only, if all values available
                    if (
                        page_data.sharedHeaderObject &&
                        page_data.targetSelection &&
                        page_data.dateRangeSelection
                    ) {
                        // Remove selectedCampus from storage, otherwise we mix up selectedCampus and chartHeaderObject
                        // It would be better, to completely separate the information, but this would mean to update all functions depending on 
                        // chartHeaderObject

                        // get campus id
                        const campusId = page_data.sharedHeaderObject.chartHeaderObject.campusId

                        // compare with accessible campuses
                        const campuses = await fetchCampuses();
                        // exists?
                        const campusExists = campuses.some(campus => campus.id === campusId);
                        if (campusExists) {

                            // Update localStorage
                            localStorage.setItem(
                                "selectedCampus",
                                JSON.stringify([campuses.find(campus => campus.id === campusId)])
                            );

                            const hObj = page_data.sharedHeaderObject.chartHeaderObject
                            hObj.hashCode = hashCode
                            localStorage.setItem(
                                "chartHeaderObject",
                                JSON.stringify(hObj)
                            );
                            const tObj = page_data.targetSelection
                            tObj.hashCode = hashCode
                            localStorage.setItem(
                                "chartTarget",
                                JSON.stringify(tObj)
                            );
                            const dObj = page_data.dateRangeSelection
                            // dObj.hashCode = hashCode
                            localStorage.setItem(
                                "selectedChartSpan",
                                JSON.stringify(dObj))
                            clearUrlParameters('/charts')
                        } else {
                            addError("The link referes to a campus without access rights or it does not exist.")
                        }
                    }
                } else {
                    addError("Failed to retrieve the link.");
                }
            } catch (error) {
                console.error("Error retrieving link:", error);
                addError("Error occurred while retrieving the link.");
            } finally {
                setLinkLoading(false);
            }
        },
        [linkLoading, addError, fetchCampuses]
    );
    useEffect(() => {

        const query = new URLSearchParams(location.search);
        const linkHashCode = query.get("link");
        if (linkHashCode) {
            handleRetrieveLink(linkHashCode);
        }
    }, [location.search,handleRetrieveLink]); 

    // Edit the name of the link
    const handleEditClick = () => {
        // TODO
        console.log("Editing of the link name is not yet implemented.")
    };


    const handleFetchMyLinks = useCallback(async () => {
        try {
            const response = await fetchMyLinks();
            if (response?.success) {
                const sortedLinks = response.data.sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                );
                setMyLinks(sortedLinks);
            } else {
                addError("Failed to fetch your links.");
            }
        } catch (error) {
            console.error("Error fetching links:", error);
            addError("Error occurred while fetching your links.");
        }
    },[addError])

    // Load data 
    useEffect(() => {
        if (isOpen || reload) {
            handleFetchMyLinks(); 
        }
    }, [isOpen, reload, handleFetchMyLinks]); 

    const handleDeleteLink = async () => {
        if (!deleteLinkHashCode) return;

        try {
            const response = await deleteChartLink(deleteLinkHashCode);
            if (response?.success) {
                addNotification("Link deleted successfully.");
                setMyLinks(
                    myLinks.filter((link) => link.hash_code !== deleteLinkHashCode)
                );
            } else {
                addError("Failed to delete the link.")
            }
        } catch (error) {
            console.error("Error deleting link:", error);
            addError("Error occurred while deleting the link.")
        }

        setDeleteDialogOpen(false);
        setDeleteLinkHashCode(null);

    };

    const handleCopyLink = (link) => {
        navigator.clipboard
            .writeText(link)
            .then(() => {
                addNotification(`Link copied to clipboard: ${link}`)
            })
            .catch(() => {
                addError("Failed to copy the link.")
            });

    };

    if (!isOpen) return null; // If not open, return null (do not render)

    return (
        <div
            style={{
                position: 'fixed',
                top: isMobile ? null : 0,
                right: 0,
                bottom: isMobile ? 0 : null,
                paddingTop: isMobile ? '0px' : '120px',
                marginTop: isMobile ? '0px' : `calc(${HEADER_HEIGHT} + ${isTablet ? '4.5rem' : '7rem'})`,
                borderRadius: isMobile ? `${BORDER_RADIUS_DRAWERS}px ${BORDER_RADIUS_DRAWERS}px 0 0` : `${BORDER_RADIUS_DRAWERS}px 0 0 ${BORDER_RADIUS_DRAWERS}px`,
                border: `1px solid ${alpha(theme.palette.primary.dark, 0.6)}`,
                borderRight: isMobile ? null : 'none',
                boxSizing: 'border-box',
                boxShadow: `2px 0px 50px ${alpha(theme.palette.primary.dark, 0.1)}`,

                width: isMobile ? '100%' : '550px', 
                background: theme.palette.background.default,

                zIndex: 1300, 
                transition: 'transform 0.3s ease-in-out',
                transform: 'translateX(0)',
                padding: "1rem",

            }}
        >
            <IconButton
                onClick={handleClose}
                style={{ position: 'absolute', top: '16px', right: '16px' }}
            >
                <CloseIcon />
            </IconButton>
            <Typography variant="h5" gutterBottom>
                {t("SHORT_LINKS")}
            </Typography>
            {myLinks.map((link, index) => {
                return (
                    <Stack
                        direction="row"
                        sx={{
                            alignItems: "center",
                            justifyContent: "flex-start",
                            margin: 1,
                        }}
                        key={`linkdiv-${index}`}
                        spacing={1}
                    >
                        <Stack
                            sx={{
                                height: '32px',

                            }}
                            direction="row"
                            key={`linkdiv-int-${index}`}
                            spacing={1}
                        >
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleCopyLink(link.target_page);
                                }}
                            >
                                <ContentCopyIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => loadFavorite(link.hash_code, index)}
                            >
                                <CachedIcon />
                            </IconButton>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDeleteDialogOpen(true);
                                    setDeleteLinkHashCode(link.hash_code);
                                }}
                            >
                                <DeleteForeverIcon />
                            </IconButton>
                        </Stack>

                        <TextField
                            id="outlined-basic"
                            value={link.name}
                            multiline
                            maxRows={2}
                            InputProps={{
                                readOnly: true, // Prevent user input
                                sx: {
                                    '& .MuiInputBase-input': {
                                        color: theme.palette.text.secondary,
                                        padding: 0,
                                        height: '100%',
                                        // padding: '16.5px 14px',
                                        fontSize: '0.875rem',
                                        textAlign: 'center',
                                        overflow: 'hidden',
                                    },
                                },
                            }}
                            sx={{
                                width: "100%",
                                // height: '60px',

                                '& .MuiOutlinedInput-root': {
                                    padding: '1px',
                                    '& fieldset': {
                                        borderColor: theme.palette.text.secondary,
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    color: theme.palette.text.secondary,
                                },
                            }}
                            variant="outlined" />
                        <IconButton
                            onClick={handleEditClick}
                        >
                            <EditIcon />
                        </IconButton>
                    </Stack>
                )

            })}
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>{t("Confirm Deletion")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t(
                            "Are you sure you want to delete this link? This action cannot be undone."
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDeleteDialogOpen(false)}
                        color="primary"
                    >
                        {t("CANCEL")}
                    </Button>
                    <Button
                        onClick={handleDeleteLink}
                        color="error" 
                        variant="contained" 
                    >
                        {t("CONFIRM")}
                    </Button>
                </DialogActions>
            </Dialog>
            {linkLoading}
            {children}
        </div>

    );
};

export default FavoriteDrawer;