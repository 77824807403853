import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate
import {
  Box,
  Typography,
  ListItem,
  Card,
  CardContent,
  List,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Workspace from "../PageStructure/Workspace";
import { deleteTask } from "../actions/api";
import { PAPER_ELEVATION } from "../../constants";

/**
 * Generic delete component. Asks for confirmation. Data is received via useLocation hook
 * @returns {JSX.Element} The rendered Delete component
 *  */
function Delete() {
  const { t } = useTranslation();

  const [showMessage, setShowMessage] = useState(false); // State for the success message
  const navigate = useNavigate(); // Use the useNavigate hook for navigation

  const location = useLocation();

  // The api func and arguments must be provided
  // apiFunc: string name of function
  // argList: array
  // data: array
  // dataKey: string
  // targetLink: string with target to navigate back
  const { apiFunc, argList, data, dataKey, targetLink } = location.state;

  console.log(apiFunc, argList, data, dataKey, targetLink);
  const handleConfirm = async () => {
    let response = null;
    if (argList.length > 0) {
      switch (apiFunc) {
        case "deleteTask":
          if (argList.length === 1) response = await deleteTask(argList[0]);
          else {
            for (const arg of argList) {
              response = await deleteTask(arg);
              if (!response) {
                break;
              }
            }
          }
          break;
        case "tbd":
          break;
        default:
          console.error("Unknown api func");
      }
    }
    // else depending on future functions
    if (response) {
      navigate(targetLink);
    } else {
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
        navigate(targetLink);
      }, 10000);
    }
  };

  const handleCancel = () => {
    navigate("/tasks");
  };

  return (
    <Workspace
      isLoading={false}
      titleIcon={<DeleteForeverIcon color="warning" sx={{ fontSize: 40 }} />}
      title={t("Delete")}
      addTitleElementRightSide={null}
    >
      <Box
        sx={{
          paddingRight: "1rem",
          paddingLeft: "1rem",
          width: "100%",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {t("DELETE_ENTRIES_INTRO")}
        </Typography>
        <Card elevation={PAPER_ELEVATION} sx={{ mt: 2, mb: 2 }}>
          <CardContent>
            <List>
              {data?.map((data) => (
                <ListItem disablePadding>
                  <Typography variant="h6" color="primary">
                    {`-  ${data[dataKey]}`}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            className="workspace"
            variant="contained"
            onClick={() => handleConfirm()}
            startIcon={<CheckIcon />}
            sx={{ marginTop: "1rem", marginLeft: "8px" }}
          >
            {t("CONFIRM")}
          </Button>
          <Button
            className="workspace"
            variant="contained"
            onClick={handleCancel}
            startIcon={<CloseIcon />}
            sx={{ marginTop: "1rem", marginLeft: "8px" }}
          >
            {t("RETURN")}
          </Button>
        </div>
        {showMessage && (
          <Typography variant="h6" color="error">
            Deleting failed. Check Errors in Console and contact your
            administrator. To open the browser console, press{" "}
            <strong>F12</strong> or <strong>Ctrl+Shift+I</strong> (Cmd+Option+I
            on Mac) to open the developer tools, then navigate to the "Console"
            tab.
          </Typography>
        )}
      </Box>
    </Workspace>
  );
}

export default Delete;
