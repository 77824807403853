import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Container,
  Card,
  Typography,
  Grid,
  Box,
  Avatar,
  Table,
  TableBody,
  TableRow,
  CardMedia,
  useTheme,
} from "@mui/material";
import { IoMail } from "react-icons/io5"; // Using react-icons for email icon
import { useTranslation } from "react-i18next";

function DetailCampus() {
  const { t } = useTranslation();
  const theme = useTheme(); // Using the theme from the context
  const { id } = useParams();
  const [campus, setCampus] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found. Redirect to the login page.");
      return;
    }

    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/customers/campuses/${id}/`;
    axios
      .get(apiUrl, config)
      .then((response) => {
        setCampus(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching campus data:", error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <CircularProgress />;
  }

  const campusImages = campus
    ? [
        campus.campus_image,
        campus.campus_image_photo_1,
        campus.campus_image_photo_2,
        campus.campus_image_photo_3,
        campus.campus_image_photo_4,
        campus.campus_image_photo_5,
      ]
    : [];

  const siteData = campus && campus.sites ? campus.sites[0] : null;
  const siteImages = siteData
    ? [
        siteData.site_image,
        siteData.site_image_photo_1,
        siteData.site_image_photo_2,
        siteData.site_image_photo_3,
        siteData.site_image_photo_4,
        siteData.site_image_photo_5,
      ]
    : [];

  return (
    <Container>
      <Link to="/campuses">
        <Button variant="contained" color="primary" style={{ margin: "16px" }}>
          {t("Back")}
        </Button>
      </Link>

      {campus ? (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            <Table>
              <TableBody>
                <TableRow>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "bold", // Make the text bold
                      color: "black", // Change the text color (optional)
                      textAlign: "center", // Center align the text
                      // Add any other custom styles you want
                    }}
                  >
                    {t("Campus Details")}
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    {campusImages.map((image, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card>
                          <CardMedia
                            component="img"
                            alt={`Campus Image ${index + 1}`}
                            height="200"
                            image={
                              image ||
                              "https://oxoia-platform.s3.eu-central-2.amazonaws.com/Image_not_available.png"
                            } // Use a placeholder image URL if image is not available
                          />
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                  <hr />
                  <Typography variant="body1">
                    <strong>{t("Campus Name")}:</strong> {campus.name || "N/A"}
                  </Typography>
                  <hr />
                  <Typography variant="body1">
                    <strong>{t("Campus Address")}:</strong>{" "}
                    {campus.address || "N/A"}
                  </Typography>
                  <hr />
                  <Typography variant="body1">
                    <strong>{t("Campus City")}:</strong> {campus.city || "N/A"}
                  </Typography>
                  <hr />
                  <Typography variant="body1">
                    <strong>{t("Campus Country")}:</strong>{" "}
                    {campus.country || "N/A"}
                  </Typography>
                  <hr />
                  <Typography variant="body1">
                    <strong>{t("Contact Info")}:</strong>{" "}
                    {campus.contact_info || "N/A"}
                  </Typography>
                  <hr />
                  <Typography variant="body1">
                    <strong>{t("Campus Type")}:</strong>{" "}
                    {campus.campus_type || "N/A"}
                  </Typography>
                  <hr />
                  <Typography variant="body1">
                    <strong>{t("Campus Description")}:</strong>{" "}
                    {campus.campus_description || "N/A"}
                  </Typography>
                  <hr />
                  <Typography variant="body1">
                    <strong>{t("Total Number of Sites")}:</strong>{" "}
                    {campus.num_sites || "N/A"}
                  </Typography>
                  <hr />
                  {/* <strong>{t("User Access")}:</strong>{" "} */}
                  {/* Add a divider for visual separation */}
                  {/* {campus.assigned_users.map((user) => (
                    <Box key={user.id} display="flex" alignItems="center" m={1}>
                      {" "}
                      {/* Add spacing */}
                  {/* <Avatar sx={{ width: 24, height: 24 }}>
                        <EmailIcon />
                      </Avatar>
                      <Typography variant="body2">{user.email}</Typography>
                    </Box>
                  {/* ))}
                  <hr /> */}{" "}
                </TableRow>

                <br />
                {siteData && (
                  <TableRow>
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: "bold", // Make the text bold
                        color: "black", // Change the text color (optional)
                        textAlign: "center", // Center align the text
                        // Add any other custom styles you want
                      }}
                    >
                      {t("Site Details")}
                      <Grid container spacing={2}>
                        {siteImages.map((image, index) => (
                          <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card>
                              <CardMedia
                                component="img"
                                alt={`Site Image ${index + 1}`}
                                height="200"
                                image={
                                  image ||
                                  "https://oxoia-platform.s3.eu-central-2.amazonaws.com/Image_not_available.png"
                                } // Use a placeholder image URL if image is not available
                              />
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Typography>
                    <hr />
                    <Typography variant="body1">
                      <strong>{t("Site Name")}:</strong>{" "}
                      {siteData.name || "N/A"}
                    </Typography>
                    <hr />
                    <Typography variant="body1">
                      <strong>{t("Site Address")}:</strong>{" "}
                      {siteData.address || "N/A"}
                    </Typography>
                    <hr />
                    <Typography variant="body1">
                      <strong>{t("Site Contact Info")}:</strong>{" "}
                      {siteData.contact_info || "N/A"}
                    </Typography>
                    <hr />
                    <Typography variant="body1">
                      <strong>{t("Site Type")}:</strong>{" "}
                      {siteData.site_type || "N/A"}
                    </Typography>
                    <hr />
                    <Typography variant="body1">
                      <strong>{t("Site Description")}:</strong>{" "}
                      {siteData.site_description || "N/A"}
                    </Typography>
                    <hr />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      ) : (
        <Typography variant="h6">{t("Campus not found.")}</Typography>
      )}
    </Container>
  );
}

export default DetailCampus;
