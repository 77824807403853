import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate
import {
    Box,
    Typography,
    useTheme,
    ListItem,
    Card,
    CardContent,
    List,
    Button
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloseIcon from '@mui/icons-material/Close';
// import CheckIcon from '@mui/icons-material/Check';
import DownloadIcon from '@mui/icons-material/Download';
import Workspace from '../PageStructure/Workspace';
// import {
//     createPdf,
// } from '../actions/api'
import {
    generateAndDownloadCarboneReport,
} from './CarboneReport'

/**
 * Generic report component. Asks for confirmation. Data is received via useLocation hook
 * @returns {JSX.Element} The rendered Delete component
 *  */
function Report() {
    const { t } = useTranslation();
    const theme = useTheme(); // Using the theme from the context
    // eslint-disable-next-line no-unused-vars
    const [showMessage, setShowMessage] = useState(false); // State for the success message
    const navigate = useNavigate(); // Use the useNavigate hook for navigation

    const location = useLocation();

    // Report templates (add to backend),  testtoken
    // TODO @Nitish Add Api call to get these from backend (type == tasks), also the. the reports need to be linked to user and partner
    // It is better not to expose the token, so it should be managed by the backend
    const templates = [{
        name: "Template 1",
        carboneId: "438961a1c3f7382667a494fca983d606f32e1eab9009f7bf4ed0552cb7cb2f9a",
        //token: "test_eyJhbGciOiJFUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI5MjE1NTQ1MTA0Mzc2MDUwNjIiLCJhdWQiOiJjYXJib25lIiwiZXhwIjoyMzgxNTczMDU4LCJkYXRhIjp7InR5cGUiOiJ0ZXN0In19.AAi4a_tnFVA9KSPIxVn5ppOnmlkA_JZoYQ0y2ait1UJu8qmZ8KwDX-6IKWW0YA6JTBmjHQozdlaHsuhmUqu4IqNmAaAHe87roADWbyUGJpGNS2VM6ybD1CHCuAe0WvAiVWOP-tuuhOKeLp6IJbtzy0xGqgqdcNspxQ4mrfXL5ijXxeeB",
        type: "tasks",
        id: 1
    },
    {
        name: "Template 2",
        carboneId: "438961a1c3f7382667a494fca983d606f32e1eab9009f7bf4ed0552cb7cb2f9a",
        //token: "test_eyJhbGciOiJFUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI5MjE1NTQ1MTA0Mzc2MDUwNjIiLCJhdWQiOiJjYXJib25lIiwiZXhwIjoyMzgxNTczMDU4LCJkYXRhIjp7InR5cGUiOiJ0ZXN0In19.AAi4a_tnFVA9KSPIxVn5ppOnmlkA_JZoYQ0y2ait1UJu8qmZ8KwDX-6IKWW0YA6JTBmjHQozdlaHsuhmUqu4IqNmAaAHe87roADWbyUGJpGNS2VM6ybD1CHCuAe0WvAiVWOP-tuuhOKeLp6IJbtzy0xGqgqdcNspxQ4mrfXL5ijXxeeB",
        type: "tasks",
        id: 1
    }]

    // The api func and arguments must be provided
    // apiFunc: string name of function, call it with eval => eval(functionName + "('Alice')");)
    // data: array
    // targetLink: string with target to navigate back
    const { apiFunc, data, targetLink } = location.state;

    const handleCancel = () => {
        navigate(targetLink);
    }

    console.log(apiFunc, data, targetLink)

    // create an object representing the json file required for building the report
    const dataConverter = (rawdata) => {
        console.log("dataConverter -> raw data", rawdata)
        // TODO: collect all the comments or provide it via  location.state
        return {}
    }

    const create = async (template) => {
        // eslint-disable-next-line no-unused-vars
        const report = generateAndDownloadCarboneReport(template.token, template.carboneId,dataConverter(data))

        
    }

    return (
        <Workspace
            isLoading={false}
            titleIcon={<PictureAsPdfIcon color="primary" sx={{ fontSize: 40 }} />}

            title={t("REPORT")}
            addTitleElementRightSide={null}
        >
            <Box sx={{ paddingRight: "1rem", paddingLeft: "1rem", width: '100%', marginRight: 'auto', marginLeft: 'auto' }}>
                <Typography variant="h6" color="primary">
                    {t("REPORT_TEMPLATE")}
                </Typography>
                
                <Card sx={{ mt: 2, mb: 2, background: theme.palette.background.paper }}>
                    <CardContent>
                    <Typography variant="body" color="primary" >
                    {t("REPORT_TEMPLATE_INTRO")}
                </Typography>
                <List>
                    {templates?.map((template) => (
                        <ListItem disablePadding>
                            <div style={{ paddingBottom: '1rem', display: "flex", alignItems: "center", justifyContent: 'flex-start' }}>
                                <div style={{ width: '180px', display: "flex" }}>
                                    <Typography variant="h6" color="primary" gutterBottom={false}>
                                        {template.name}
                                    </Typography>
                                </div>
                                <Button 
                                className="workspace" 
                                variant="contained" 
                                onClick={() => create(template)} 
                                startIcon={<DownloadIcon />} 
                                >

                                    {t("DOWNLOAD")}
                                </Button>
                            </div>
                        </ListItem>
                    ))}

                </List>
                </CardContent>
                </Card>
                
                <div style={{ width: '100%', display: "flex", alignItems: "center", justifyContent: "flex-end", }}>
                    <Button className="workspace" variant="contained" onClick={handleCancel} startIcon={<CloseIcon />} sx={{ marginTop: "1rem", marginLeft: "8px" }}>
                        {t("RETURN")}
                    </Button>
                </div>
                {showMessage && (
                    <Typography variant="h6" color="error">
                        Deleting failed. Check Errors in Console and contact your administrator.
                        To open the browser console, press <strong>F12</strong> or <strong>Ctrl+Shift+I</strong> (Cmd+Option+I on Mac) to open the developer tools, then navigate to the "Console" tab.
                    </Typography>
                )}
            </Box>
        </Workspace>
    );
}

export default Report;
