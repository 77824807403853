import React, { createContext, useContext, useState } from 'react';

const NotificationContext = createContext();

export const useNotification = () => {
    return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const [errors, setErrors] = useState([]);

    const addNotification = (message) => {
        setNotifications((prev) => [...prev, message]);
    };
    const addError = (message) => {
        setErrors((prev) => [...prev, message]);
    };

    const removeNotification = (index) => {
        setNotifications((prev) => prev.filter((_, i) => i !== index));
    };

    const removeError = (index) => {
        setErrors((prev) => prev.filter((_, i) => i !== index));
    };

    setTimeout(() => {
        removeNotification(0)
        removeError(0)
      }, 6000);

    return (
        <NotificationContext.Provider value={{ notifications, addNotification, removeNotification, errors, addError, removeError }}>
            {children}
        </NotificationContext.Provider>
    );
};