import React, { forwardRef, useEffect, useLayoutEffect, useRef, useMemo, useState } from 'react';
import Quill from 'quill';
import { useNavigate } from "react-router-dom";
import {
  Button,
} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "./quill.css";
import { useTranslation } from "react-i18next";

// This file is taken from: https://quilljs.com/playground/react
const Editor = forwardRef(
  ({ ro, defaultValue, onTextChange, onSelectionChange, buttonHandler }, ref) => {
    const containerRef = useRef(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [readOnly, setReadOnly] = useState(ro)
    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
    });

    useEffect(() => {
      setReadOnly(ro);
    }, [ro]);
    useEffect(() => {
      ref.current?.enable(!readOnly);
    }, [ref, readOnly]);

    // Toolbar options
    const options = useMemo(() => {

      const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        //['blockquote', 'code-block'],
        ['link'], //, 'image', 'video', 'formula'

        [{ 'header': 1 }, { 'header': 2 }, { 'header': 3 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
        //[{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

       // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
       // [{ 'font': [] }],
        //[{ 'align': [] }],

        ['clean']                                         // remove formatting button
      ];
      return {
        //debug: 'info',
        modules: {
          toolbar: toolbarOptions,
        },
        theme: readOnly ? 'bubble' : 'snow',

      };
    }, [readOnly]);




    useEffect(() => {
      const container = containerRef.current;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div'),
      );
      const quill = new Quill(editorContainer, options);
      quill.getModule('toolbar').addHandler('link', function(value) {
        if (value) {
                const href = prompt('Enter the URL');
                this.quill.format('link', href);
                //this.quill.theme.tooltip.edit('link', href);
              } else {
                this.quill.format('link', false);
              }
        
      });
      ref.current = quill;

      if (defaultValueRef.current) {
        quill.setContents(defaultValueRef.current);
      }

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        onTextChangeRef.current?.(...args);
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
      });

      return () => {
        ref.current = null;
        container.innerHTML = '';
      };
    }, [ref, options]);

    const handleDoubleClick = () => {
      setReadOnly(false)
    };

    const handleCancel = () => {
      navigate("/tasks");
    }
    return (
      <>
        <div onClick={handleDoubleClick} ref={containerRef}></div>
        <div style={{ width: '100%', display: readOnly ? "none" : "flex", alignItems: "center", justifyContent: "flex-end", }}>
          <Button className="workspace" disabled={readOnly ? true : false} startIcon={<SaveIcon />} variant="contained" sx={{ marginTop: 2, marginRight: 1, marginBottom: 0 }} onClick={(e) => buttonHandler(e, onTextChangeRef.current)}>
            {t("COMMIT")}
          </Button>
          <Button className="workspace" variant="contained" onClick={handleCancel} startIcon={<CloseIcon />} sx={{ marginTop: 2, marginRight: 0, marginBottom: 0 }}>
            {t("RETURN")}
          </Button>
        </div>

      </>
    )
  },
);

Editor.displayName = 'Editor';

export default Editor;