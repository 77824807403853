import React from "react";

const Maintenance = () => {
  return (
    <div style={{ textAlign: "center", padding: "100px" }}>
      <h1>We are currently under maintenance</h1>
      <p>Please check back later.</p>
    </div>
  );
};

export default Maintenance;
