

/**
 * Mutation oberver, wait until element is ready
 * @param {String} id - Id of element
 * @param {Number} timeout - Time out period in milliseconds
 * @return {Object} DOM element
 *  */
async function waitForElementById(id, timeout = null, location = document.body) {
    return new Promise((resolve, reject) => {
      const startTime = Date.now();
      const observer = new MutationObserver((mutationsList, observer) => {
        const targetNode = document.getElementById(id);
        if (targetNode) {
          observer.disconnect();
          resolve(targetNode);
        } else if (timeout && Date.now() - startTime >= timeout) {
          observer.disconnect();
          reject(new Error(`Timeout waiting for element with id '${id}'`));
        }
      });
  
      observer.observe(location, { childList: true, subtree: true });
  
      // Check if the element already exists
      const targetNode = document.getElementById(id);
      if (targetNode) {
        observer.disconnect();
        resolve(targetNode);
      }
    });
  }

// Clear a search link and replace it with the page
const clearUrlParameters = (replacement) => {
    // Get the current URL
    const currentUrl = window.location.href;

    // Create a new URL object
    const url = new URL(currentUrl);

    // Clear the search parameters
    url.search = '';

    // Set the hash to the desired path without parameters
    window.location.hash = replacement; 

    // Reload data
    window.location.reload(); 
};
  
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

/**
 * Shorten an avatar name by the first two letters an create a random background color
 * @param {String} name - Id of element
 * @return {Object} Object with sx key (bgColor) and children key (two letters)
 *  */
  function stringAvatar(name) {
  
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[0][1]}`, // take first two letters
    };
  }


export {
    waitForElementById,
    stringAvatar,
    clearUrlParameters,
}