import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, Popper, Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { useDrawerWidth } from "../../size";

/**
 * Generic autocomplete field for large and small screens
 * @param {Array} dataList - List with elements (objects or strings, both possible)
 * @param {Function} changeHandler - Function that handles onChange callback, returns selected value
 * @param {String/Array} value - Actual selection, presented in field, with multiple selection, this must be an Array
 * @param {Object} style - Styling props, handed over to sx field of Autocomplete
 * @param {Object} popperStyle - Styling props for popper, set 'placement' and 'width'
 * @param {String} size - String for Autocomplete property 'size', 'small' or 'medium'
 * @param {String} label - Lable for text field (user help)
 * @param {String} value - Actual selection, presented in field
 * @param {Boolean} multiple - Multiple selection
 * @returns {JSX.Element} The rendered Autocomplete component
 *  */
const GenericAutocomplete = ({
  dataList,
  changeHandler,
  value,
  style,
  size,
  label,
  popperStyle = {},
  multiple = false,
}) => {
  const theme = useTheme();
  const [all, setAll] = useState([]);
  const drawerWidth = useDrawerWidth();

  useEffect(() => {
    if (dataList !== undefined && dataList !== null) {
      // test if dataList is array of object or an array of strings
      const isArrayOfStrings = dataList.every(
        (item) => typeof item === "string"
      );
      let mod = [];
      if (isArrayOfStrings) {
        mod = dataList.map((val) => {
          let x = {};
          x["label"] = val;
          return x;
        });
      } else {
        mod = dataList.map((campus) => {
          campus["label"] = campus.name;
          return campus;
        });
      }
      setAll(mod);
    }
  }, [dataList]);

  // Increase the popper width, where necessary
  const StyledPopper = styled(Popper)({
    "& .MuiAutocomplete-listbox": {
      width: popperStyle.width,
    },
    "& .MuiAutocomplete-popper": {
      width: popperStyle.width,
    },
    "& .MuiAutocomplete-paper": {
      width: popperStyle.width,
    },
  });
  // Placement options
  //   "bottom-start" (default)
  // "bottom"
  // "bottom-end"
  // "top-start"
  // "top"
  // "top-end"
  // "left-start"
  // "left"
  // "left-end"
  // "right-start"
  // "right"
  // "right-end"
  const PopperWithPlacement = (props) => {
    return <StyledPopper {...props} placement={popperStyle.placement} />;
  };

  return (
    <Autocomplete
      disablePortal
      multiple={multiple}
      // getOptionLabel={(option) => option.name}
      id={all.length === 0 ? "noId" : all[0].label}
      size={size}
      options={all}
      PopperComponent={PopperWithPlacement}
      onChange={(event, newValue) => {
        // return object
        changeHandler(newValue);
      }}
      value={value}
      sx={{
        marginBottom: "1rem",
        ...style,
      }}
      renderInput={(params) => (
        <TextField
          className="selectorAuto"
          {...params}
          key={label}
          label={label}
          error={!value || (Array.isArray(value) && value.length === 0)}
          InputProps={{
            ...params.InputProps,
            style: {
              borderColor:
                !value || (Array.isArray(value) && value.length === 0)
                  ? theme.palette.error.dark
                  : "inherit",
            },
          }}
          variant="outlined"
        />
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const { key, ...otherTagProps } = getTagProps({ index }); // Destructure to remove key
          return (
            <Chip
              key={option.id || index} // Explicitly add key here
              label={option.name} // Adjust this to the property you want to display
              className={drawerWidth === 0 ? "mobile" : ""}
              {...otherTagProps} // Spread without key
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.secondary.contrastText,
                "& .MuiChip-deleteIcon": {
                  color: theme.palette.secondary.contrastText,
                },
              }}
            />
          );
        })
      }
    />
  );
};

export default GenericAutocomplete;
