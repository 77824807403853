import React from "react";
import { useNavigate } from "react-router-dom";
import { MenuItem, ListItemIcon, Typography } from "@mui/material";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import { useTranslation } from "react-i18next";

const ColorSettings = ({ handleClose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <MenuItem
      style={{
        marginBottom: "1rem",
      }}
      className="settings"
      onClick={() => {
        navigate("/theme");
        handleClose();
      }}
    >
      <ListItemIcon className="settings">
        <FormatColorFillIcon />
      </ListItemIcon>
      <Typography variant="subtitle1" className="settings">
        {t("COLORS")}
      </Typography>
    </MenuItem>
  );
};

export default ColorSettings;
