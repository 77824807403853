import React from "react";
import {
    Box,
    Typography,
} from "@mui/material";

const PageTitle = ({ children, rightSideElement }) => {

    return (
        <Box
            sx={{
                paddingTop: "1rem",
                marginTop: '0px',
                marginBottom: "1rem",
                display: 'flex',
                flexDirection: "row",
                alignItems:'center',
                width:'100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width:'100%',
                }}
            >
            <Typography variant="h4" component="h1" >
                {children}
            </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems:'center',
                    //width:'100%',
                }}
            >
                {rightSideElement}
            </Box>
        </Box>
    );
};

export default PageTitle;